export type AdminMenuT = AdminMenuItemT[]

export type AdminMenuItemT = {
    name: string;
    order: number;
    img: string;
    link: string;
    subItems?: AdminMenuItemT[];
}


export enum Status {
    'pending' = 'pending',
    'approve' = 'approve',
    'cancel' = 'cancel',
}