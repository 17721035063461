import { useEffect } from "react"

export const Page3 = () => {
    useEffect(() => {  window.scrollTo(0, 0) }, [])
    
    return (
        <div className="mx-4  home">
            <h1 className="">Почесні члени</h1>
           

        </div >
    )
}