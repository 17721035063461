import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getKyrses } from "../../services/local-data/kyrses";
import { Kyrse } from "../../types/Kyrse";
import './kyrs.css';
import GoogleSlider from "../../components/GoogleSlider/google_slider";

export const Kyrcu_list = () => {
    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (
        <div className="mx-4  home">
            <div>
                <h5 className="text-center">Шановні колеги! </h5>

                <p>
                    НАПУАУ започаткувала цей Розділ веб-порталу, керуючись потребою підвищення кваліфікації публічними службовцями через проходження програм, які охоплюють загальні питання державного управління та місцевого самоврядування, питання запобігання корупції, зміни у законодавстві з питань державної служби, служби в органах місцевого самоврядування, європейської та євроатлантичної інтеграції, із забезпечення рівних прав та можливостей жінок та чоловіків, підвищення рівня володіння іноземною мовою, яка є офіційною мовою Ради Європи, державною мовою, а також інші питання, визначені відповідними державними, регіональними, місцевими програмами, стратегіями.
                </p>
                <p>
                    Вашій увазі пропонуються короткострокові програми обсягом 0,2 – 1 кредит ЄКТС. Напрями програм будуть додаватися відповідно до переліку пріоритетних напрямів (тем) підвищення кваліфікації державних службовців за загальними професійними (сертифікатними) та короткостроковими програмами та відповідно до актуальних питань, які будуть визначатися державними, регіональними, місцевими програмами.
                </p>
                <p>
                    НАПУАУ пропонує програми, за якою учасники професійного навчання підвищують кваліфікацію, які можуть відповідати виду професійного навчання та орієнтовному напряму (темі), зазначеним в індивідуальній програмі підвищення рівня професійної компетентності державного службовця / індивідуальній програмі професійного розвитку.
                </p>
                <p>
                    За результатами підвищення кваліфікації за програмами, за умови їх успішного виконання, видається сертифікат про підвищення кваліфікації, а за короткостроковими програмами — відповідний документ про підвищення кваліфікації.
                </p>
                <p className="py-2">
                    У цьому розділі веб-порталу розміщені програми підвищення кваліфікації від 0,2 до 1 кредиту ЄКТС. За результати проходження програм Ви зможете згенерувати Сертифікат, який засвідчуватиме проходження вибраної програми.
                </p>
                <p>
                    Зазначимо, що відповідно до Положення про систему професійного навчання державних службовців, голів місцевих державних адміністрацій, їх перших заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих рад учасники професійного навчання можуть реалізовувати своє право на професійне навчання через:
                    <br /> <b> підготовку - </b> успішне виконання учасниками професійного навчання відповідної освітньо-професійної програми, що є підставою для присудження ступеня вищої освіти за рівнем магістра за спеціальностями, необхідними для професійної діяльності на державній службі та службі в органах місцевого самоврядування, зокрема за спеціальністю 281 “Публічне управління та адміністрування” галузі знань “Публічне управління та адміністрування”;
                    <br /> <b> підвищення кваліфікації -</b> набуття учасниками професійного навчання нових та/або вдосконалення раніше набутих компетентностей у межах професійної діяльності або галузі знань;
                    <br /><b>стажування - </b> набуття учасниками професійного навчання практичного досвіду виконання завдань та обов’язків у професійній діяльності або галузі знань;
                    <br /><b>самоосвіту -</b> самоорганізоване здобуття учасниками професійного навчання певних компетентностей, зокрема під час повсякденної діяльності, пов’язаної з професійною, громадською або іншою діяльністю, дозвіллям.
                </p>
                <p>
                    За результатами професійного навчання державним службовцям, посадовим особам місцевого самоврядування нараховуються кредити ЄКТС, які підлягають обліку, що ведеться службами управління персоналом.
                    Державні службовці у межах виконання індивідуальних програм та посадові особи місцевого самоврядування мають набирати не менше одного кредиту ЄКТС протягом календарного року, у тому числі 0,1-0,2 кредиту ЄКТС шляхом самоосвіти.
                    (Абзац другий пункту 7 із змінами, внесеними згідно з Постановою  КМ &nbsp;
                    <a href="https://zakon.rada.gov.ua/laws/show/446-2021-%D0%BF#n33" target="_blank"> № 446 від 28.04.2021 </a>
                    )
                </p>
                <p>
                    У разі введення правового режиму надзвичайного стану, правового режиму воєнного стану, встановлення режиму надзвичайної ситуації, карантину відповідно до
                    Закону України &nbsp;<a href="https://zakon.rada.gov.ua/laws/show/1645-14#Text" target="_blank">
                        “Про захист населення від інфекційних хвороб”
                    </a>
                    &nbsp; частка самоосвіти в межах виконання індивідуальної програми у відповідному календарному році може становити не більше 0,5 кредиту ЄКТС.
                    Пункт 7 доповнено абзацом згідно з Постановою КМ
                    <a href="https://zakon.rada.gov.ua/laws/show/446-2021-%D0%BF#n33" target="_blank"> № 446 від 28.04.2021  </a>
                    ;із змінами, внесеними згідно з Постановою КМ
                    <a href="https://zakon.rada.gov.ua/laws/show/846-2022-%D0%BF#n13" target="_blank"> № 846 від 29.07.2022 </a>
                    ; в редакції Постанови КМ
                    <a href="https://zakon.rada.gov.ua/laws/show/1494-2022-%D0%BF#n10" target="_blank"> № 1494 від 30.12.2022 </a>
                </p>
                <p>
                    Джерело: Постанова Кабінету Міністрів України Джерело: Про затвердження Положення про систему професійного навчання державних службовців, голів місцевих державних адміністрацій, їх перших заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих рад
                    <a href=" https://zakon.rada.gov.ua/laws/show/106-2019-%D0%BF#n14" target="_blank"> № 106 від 06.02.2019 . </a>
                </p>




                {/* ___________________________Навчальні курси___________________________ */}

                <br />
              

                <h4 className="text-center">Програми</h4>

                <table className="table table-bordered table-hover kyrs-table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Назва програми</th>
                            <th scope="col">Кількість</th>
                            <th scope="col">Вартість, грн.</th>
                            <th scope="col">Вартість для членів НАПУАУ,грн.</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            getKyrses().map((kyrs: Kyrse, i: number) => (
                                <tr key={i}>
                                    <th scope="row">{kyrs.id}</th>
                                    <td>
                                        <NavLink to={`/kyrs/${kyrs.id}`} >
                                            {kyrs.name}
                                        </NavLink>
                                    </td>
                                    <td>{kyrs.credits} кредит ЄКТС/{kyrs.durationHours} годин </td>
                                    <td>{kyrs.price}</td>
                                    <td>{kyrs.priceMembers}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>





                {/* __________________________Нормативно-правові акти____________________________ */}

                <h4 className="text-center"><br /><br />  Нормативно-правові акти,  що регулюють питання підвищення кваліфікації публічних службовців:</h4>

                <p>
                    <a href="https://zakon.rada.gov.ua/laws/show/889-19#Text" target="_blank" rel="noopener noreferrer">
                        Закон України «Про державну службу»
                    </a>
                </p>
                <p>
                    <a href="https://zakon.rada.gov.ua/laws/show/106-2019-%D0%BF#Text" target="_blank" rel="noopener noreferrer">
                        Положення про систему професійного навчання державних службовців, голів місцевих державних адміністрацій, їх перших заступників та заступників, посадових осіб місцевого самоврядування та депутатів місцевих рад
                    </a>
                </p>
                <p>
                    <a href="https://zakon.rada.gov.ua/laws/show/z1288-19#Text" target="_blank" rel="noopener noreferrer">
                        Порядок нарахування кредитів ЄКТС за результатами професійного навчання
                    </a>
                </p>
                <p>
                    <a href="https://zakon.rada.gov.ua/laws/show/z1289-19#Text" target="_blank" rel="noopener noreferrer">
                        Порядок визнання документів та результатів навчання за програмами підвищення кваліфікації, а також участі у заходах з обміну досвідом
                    </a>
                </p>
            </div>

        </div >
    )
}