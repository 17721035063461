import { AdminMenuT } from "../../types/AdnimMenu"

const adminMenu: AdminMenuT = [
    {
        name: 'Заявки', order: 1, img: '', link: '/admin/applications',
        subItems: [
            { name: 'На координатора', order: 1, img: '', link: '/admin/applications/1' },
            { name: 'На посвідчення', order: 1, img: '', link: '/admin/applications/2' },
            { name: 'На сертифікат В2', order: 1, img: '', link: '/admin/applications/3' },
        ]
    },
    {
        name: 'Користувачі', order: 1, img: '', link: '',
        subItems: [
            { name: 'Члени Організації', order: 1, img: '', link: '' },
            { name: 'Студенти', order: 1, img: '', link: '' },
        ]
    }
    ,
    {
        name: 'Оплати', order: 1, img: '', link: '',
        subItems: [
            { name: 'Вступні внески', order: 1, img: '', link: '' },
            { name: 'Оплачені курси', order: 1, img: '', link: '' },
        ]
    },
    {
        name: 'Документи', order: 1, img: '', link: '',
        subItems: [
            { name: 'Сертифікати', order: 1, img: '', link: '' },
            { name: 'Посвідчення', order: 1, img: '', link: '' },
        ]
    },
    {
        name: 'Юридична допомога', order: 1, img: '', link: '/admin/law',

    }
]

export const getAdminMenu = () => adminMenu

