import { useEffect, useState, useCallback, FC } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext } from "./core/auth";
import ErrorBoundary from "./components/ErrorBoundary";
import { firebaseAuth } from "./core/firebase";
import { User } from "./types/auth";
import { LoginForm } from "./components/form/login-form";
import { getData } from './services/firebase-store.service';
import { NotificationSvc } from "./components/notification/Notification";
import { PrivateRoute } from "./components/ProtectedRoute";
import Layout from "./pages/Layout";
import './index.css';
import './components/navigation/navbar.css'
import { Page2 } from "./pages/Page2";
import { Page3 } from "./pages/Page3";
import { Kyrcu_list } from "./pages/Kyrsu/Kyrcu_list";
import { PageContacts } from "./pages/Contacts";
import { Home } from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import ProfileView from "./pages/ProfileView";
import { Structura } from "./pages/Structura";
import Kyrs from "./pages/Kyrsu/Kurs";
import { Questions } from "./pages/questions";
import { NewsPage } from "./pages/News/NewsPage";
import { Payment } from "./pages/Payment";
import { Applications } from "./pages/Admin/Pages/Applications";
import Dashboard from "./pages/Admin/Dashboard";
import LayoutAdmin from "./pages/Admin/Layout-admin";
import { Users } from "./pages/Registers/Users";
import { Certificates } from "./pages/Registers/Certificates";
import { LawPage } from "./pages/Law/law";
import { LawAdmin } from "./pages/Admin/Pages/Law";
import { InfoPage } from "./pages/Info/info";
import { CertificatesB2 } from "./pages/Certificate-B2/certificates-b2";

export const App: FC = () => {
  const [user, setUser] = useState<User | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { notifications } = NotificationSvc();

  const changeUser = useCallback((user: any) => {
    user && user.roles && user.roles.length !== 0
      ? setUser(new User(user))
      : setUser(user);
    setLoading(false);
  }, []);


  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async usr => {
      if (usr && !user) {
        setLoading(true);
        const _user = await getData(usr.uid, 'users');
        changeUser(_user);
      } else if (!usr) {
        changeUser(null);
      }
    });
    return () => unsubscribe();
  }, [user]);


  return (
    <ErrorBoundary>
      <AuthContext.Provider value={{
        user,
        changeUser,
        setLoading,
      }}>

        {
          loading &&
          <div className="spiner">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }

        {notifications()}


        <Router>
          <Switch>
            <Route path="/login" component={LoginForm} />

            <Route path='/admin/:path?' >
              <LayoutAdmin >
                <Switch>
                  <Route path='/admin' exact component={Dashboard} />
                  <Route exact path="/admin/applications/:id?" component={Applications} />
                  <Route exact path="/admin/law" component={LawAdmin} />
                </Switch>
              </LayoutAdmin >
            </Route>

            <Layout>
              <Route exact path="/" component={Home} />
              <Route exact path="/upravlinya" component={Page2} />
              <Route exact path="/pochesni" component={Page3} />
              <Route exact path="/kyrsu" component={Kyrcu_list} />
              <Route exact path="/kyrs/:id" component={Kyrs} />
              <Route exact path="/structura" component={Structura} />
              <Route exact path="/contacts" component={PageContacts} />
              <Route exact path="/user/:id" component={ProfileView} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/questions" component={Questions} />
              <Route exact path="/news" component={NewsPage} />
              <Route exact path="/news/:id" component={NewsPage} />
              <Route exact path="/payment/:id" component={Payment} />

              <Route exact path="/users" component={Users} />
              <Route exact path="/certificates" component={Certificates} />
              <Route exact path="/certificates-b2" component={CertificatesB2} />
              <Route exact path="/law" component={LawPage} />
              <Route exact path="/info/:id" component={InfoPage} />
              {/* {
                user ? <PrivateRoute user={user} exact path="/profile" comp={Profile} />
                  : <Route exact path="/profile" component={Home} />
              } */}
            </Layout>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};
