
import { NotificationSvc } from '../components/notification/Notification';
import { firestore, storage } from "../core/firebase"
import { TypeData, User, getUserValuesFromFirebaseUser } from '../types/auth';
import { News } from '../types/news';
import { getData, updateUser } from './firebase-store.service';
// import { collection, query, where, getDocs } from "firebase/firestore";

// const q = query(collection(db, "cities"), where("capital", "==", true));
const { onWarning, onError } = NotificationSvc();


export const getLawQuestions = async () => {
    const ref1: any = firestore.collection('laws')
    const data: any = [];

    await ref1.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                const _data = doc.data()
                _data.id = doc.id
                data.push(_data)
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const updateLawQuestions = async (item: any) => {
    const ref: any = firestore.collection('laws')
    await ref.doc(`${item.id}`).update({ ...item })
}

export const getApplications = async (type: any) => {
    const ref1: any = firestore.collection('coordinatorRequest')
    const ref2: any = firestore.collection('memberCertificateRequest')
    const ref3: any = firestore.collection('certificatesB2')
    const data: any = [];

    if (!type) {
        await ref1.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
        await ref2.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
        await ref3.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
    }

    if (type == 1) {
        await ref1.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
    }

    if (type == 2) {
        await ref2.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
    }
    if (type == 3) {
        await ref3.get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const _data = doc.data()
                    _data.id = doc.id
                    data.push(_data)
                })
            })
            .catch((error: any) => onError("Error getting document: ", error));
    }
    return data;
}


export const updateApplication = async (item: any) => {
    let ref: any;
    let _u: any = null
    if (item.userUID && item.userUID.length > 0) {
        _u = await getData(item.userUID, 'users')
    }

    if (item.data) {
        ref = firestore.collection('memberCertificateRequest')
        if (_u) {
            _u.memberCertificateStatus = item.status;
        }
    } else {
        if (item.address) {
            ref = firestore.collection('certificatesB2');
            if (_u) {
                _u.certificatesB2 = item.status;
            }
        } else {
            ref = firestore.collection('coordinatorRequest');
            if (_u) {
                _u.coordinatorStatus = item.status;
            }
        }
    }
    await ref.doc(`${item.id}`).update({ ...item })
    if (_u) {
        await updateUser(_u.uid, _u)
    } else {
        onWarning('Даний користувач не зареєстрований. Відсутня змога повідомити користувачу про дану операцію')
    }
}



