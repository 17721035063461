import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ _value, _change }: any) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (_value) {
      setValue(_value)
    }
  }, []);

  const modules = {
    toolbar: [
      // [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ["link",
      ],
      // [
      //   { list: "ordered" },
      //   { list: "bullet" },
      //   // { indent: "-1" },
      //   // { indent: "+1" },
      //   // { align: [] }
      // ],
      // [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  const formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link",
    "align",
    "size",
  ];

  const handleProcedureContentChange = (content: any, delta: any, source: any, editor: any) => {
    _change(content)
    setValue(content)
  };

  return (
    <div >
      <ReactQuill
        value={_value}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="write your content ...."
        onChange={handleProcedureContentChange}
      // style={{ height: "520px", fontSize: '20px' }}
      />
    </div>
  );

}

export default TextEditor;