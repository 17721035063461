import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../core/auth";
import { TabData, TypeData, User, UserTab } from "../types/auth";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getUserData } from "../services/firebase-store.service";
import { NotificationSvc } from "../components/notification/Notification";

const ProfileView = (props: RouteComponentProps) => {
    const { user, setLoading } = useContext(AuthContext);
    const [_user, setUser] = useState<User | null>();
    const [userID, setUserID] = useState<string>('');
    const [activeTab, setActiveTab] = useState<UserTab | undefined>()
    const { onSuccess } = NotificationSvc();

    useEffect(() => {
        const params: any = props.match.params;
        if (params && params.id) {
            setUserID(params.id);
        }
    }, []);

    useEffect(() => {
        getData();
        return
    }, [userID]);

    useEffect(() => { window.scrollTo(0, 0) }, [])

    const getData = async () => {
        setLoading(true);
        const _user: any = await getUserData(userID);
        setUser(_user);
        setActiveTab(_user?.tabs[0])
        setLoading(false);
    }

    const copyToClipboard = () => {
        const range = document.createRange();
        const a = document.getElementById("copy-text");
        if (a) {
            range.selectNode(a);
            window.getSelection()?.removeAllRanges(); // clear current selection
            window.getSelection()?.addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection()?.removeAllRanges();// to deselect
            onSuccess('Скопійовано!');
        }
    }

    return (
        <div className="mx-4 pt-5 ">
            <div className="content">

                <div className="container mt-5">
                    <div className="row pt-5 mt-5 user-info">
                        <div className="col-md-7">
                            <h4>
                                {_user?.lastName} {_user?.firstName}     {_user?.surName}
                            </h4>
                            {_user?.workPlace ?
                                <div className="mt-2">
                                    <span>Місце роботи:</span> {_user?.workPlace}
                                </div>
                                : null
                            }
                            {_user?.position ?
                                <div className="mt-2">
                                    <span>Посада:</span> {_user?.position}
                                </div>
                                : null
                            }

                            <h4 className="pt-4">Контакти</h4>
                            {_user?.contacts && _user?.contacts.map((cont: any, i: number) => {
                                return cont.checked &&
                                    <div key={i} className="mt-2">
                                        <span>{cont.name}:</span> {cont.value}
                                    </div>
                            })
                            }
                            <div className="mt-2">
                                <span>Посилання на особистий профіль:</span> <div id="copy-text" className="px-5">
                                    {`https://unapam.com/user/${userID}`}
                                    <i onClick={() => copyToClipboard()} className="px-3 pt-2 fas fa-copy"></i>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 ava">
                            {_user?.photoURL
                                ? <img style={{ width: '200px' }} src={`${_user?.photoURL}`}></img>
                                : <i style={{ fontSize: '140px' }} className="far fa-user"> </i>
                            }
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-3">
                            <div className="profile-cat">
                                <div className="cat-header">Категорії</div>
                                {
                                    _user?.tabs?.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)).map((tab: UserTab, i: number) =>
                                        tab.data && tab.data.length > 0 &&
                                        <div key={i} className={tab.name == activeTab?.name ? 'active cat-item' : 'cat-item'} onClick={() => setActiveTab(tab)}>
                                           {tab.name.replaceAll('_', ' ')}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row my-1 under-line">
                                {
                                    activeTab && activeTab?.data.map((el: TabData, ii: number) => {
                                        return el.value ?
                                            <div key={ii} className="text-center my-1 item-category">
                                                {
                                                    el.type == TypeData.File
                                                        ?
                                                        <div key={ii} className="  mx-3 my-2 certificate">
                                                            <img style={{ width: '90%', maxWidth: '400px' }} src={`${el.value}`}></img>
                                                        </div>
                                                        : el.type == TypeData.PDF
                                                            ?

                                                            <div key={ii} className="  mx-3 my-2 certificate">
                                                                <object data={el.value} type="application/pdf" width="100%" height="850px"> </object>
                                                            </div>
                                                            : el.type == TypeData.Link
                                                                ? <a target="_blank" href={el.value}>{el.name ? el.name : el.value}</a>
                                                                : <div className="my-3"> <pre>{el.value} </pre> </div>
                                                }
                                            </div>
                                            : null
                                    })
                                }
                            </div></div>
                    </div>

                    {/* <div className="row my-4">
                        <div className="accordion accordion-flush" id="accordionCategories">
                            {
                                _user?.tabs?.sort((a: any, b: any) => (a.order > b.order ? 1 : -1)).map((tab: UserTab, i: number) =>
                                    tab.data && tab.data.length > 0 &&
                                    <div key={i} className="mt-2 accordion-item">
                                        <h2 className="accordion-header" id={`flush-heading-${i}`}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#flush-collapse-${i}`}
                                                aria-expanded="false"
                                                aria-controls={`flush-collapse-${i}`}
                                            >
                                                {tab.name.replaceAll('_', ' ')}
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse-${i}`}
                                            className={`accordion-collapse ${i == 0 ? '' : 'collapse'} `}
                                            aria-labelledby={`flush-heading-${i}`}
                                            data-bs-parent="#accordionCategories"
                                        >
                                            <div className="accordion-body">
                                                <div className="row my-1 under-line">
                                                    {
                                                        tab.data.map((el: TabData, ii: number) => {
                                                            return el.value ?
                                                                <div key={ii} className="text-center my-1 item-category">
                                                                    {
                                                                        el.type == TypeData.File
                                                                            ?
                                                                            <div key={ii} className="  mx-3 my-2 certificate">
                                                                                <img style={{ width: '90%', maxWidth: '400px' }} src={`${el.value}`}></img>
                                                                            </div>
                                                                            : el.type == TypeData.PDF
                                                                                ?

                                                                                <div key={ii} className="  mx-3 my-2 certificate">
                                                                                    <object data={el.value} type="application/pdf" width="100%" height="850px"> </object>
                                                                                </div>
                                                                                : el.type == TypeData.Link
                                                                                    ? <a target="_blank" href={el.value}>{el.name ? el.name : el.value}</a>
                                                                                    : <div className="my-3"> <pre>{el.value} </pre> </div>
                                                                    }
                                                                </div>
                                                                : null
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default withRouter(ProfileView);