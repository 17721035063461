
import { NotificationSvc } from '../components/notification/Notification';
import { firestore, storage } from "../core/firebase"
import { TypeData, User, getUserValuesFromFirebaseUser } from '../types/auth';
import { News } from '../types/news';

const { onSuccess, onError } = NotificationSvc();

export const getTest = async (testId: string) => {
    const doc = await firestore.collection('tests').doc(testId).get();
    return doc?.data();
}

export const addInfo = async (info: any, id: string) => {
    try {
        await firestore.collection('info').doc(id).set({ ...info })
        return info;
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const getInfo = async (id: string) => {
    const ref: any = await firestore.collection('info').doc(`${id}`).get();
    return ref.data();
}

export const updateInfo = async (info: News, id: string) => {
    try {
        await firestore.collection('info').doc(id).update({ ...info })
        return info
    } catch (error) {
        onError("Error updating document: ", error);
    }
}


export const addMemberCertificateRequest = async (form: any, user: any) => {
    form.userName = `${user.lastName} ${user.firstName} ${user.surName}`;
    form.profileId = user.profileId;
    form.userUID = user.uid;

    const _user = { ...user, memberCertificateStatus: 'pending' }
    const ref = await firestore.collection('memberCertificateRequest').doc(`${user.profileId}`);
    ref.set(form)
    updateUser(_user.uid, _user)
    onSuccess('Certificate was send successfully');
    return _user;
}

export const addCoordinatorRequest = async (form: any, user: any) => {
    form.userName = `${user.lastName} ${user.firstName} ${user.surName}`;
    form.profileId = user.profileId;
    form.userUID = user.uid;
    form.status = 'pending';

    const _user = { ...user, sign: form.sign, coordinatorStatus: 'pending' }
    const ref = await firestore.collection('coordinatorRequest').doc(`${user.profileId}`);
    ref.set(form)
    updateUser(_user.uid, _user)
    onSuccess('Form was send successfully');
    return _user;
}

export const getCertificate = async (uid: any) => {
    const ref: any = await firestore.collection('certificates').doc(uid).get()
    return ref.data();
}

export const addCertificate = async (uid: any, cert: any) => {
    const ref = await firestore.collection('certificates').doc(uid)
    let res: any = await (await ref.get()).data();
    if (res?.certificates) {
        const c = res.certificates.find((f: any) => f.id == cert.id);
        if (!c)
            res.certificates = [...res.certificates, cert];
    } else {
        res = { certificates: [cert] };
    }
    ref.set(res);
    return cert
}

export const updateTest = async (testId: string, test: any) => {
    await firestore.collection('tests').doc(testId).set(test)
    onSuccess('Test was added successfully');
}

export const addTest = async (testId: string, test: any) => {
    const ref = await firestore.collection('tests').doc(testId);
    const res: any = await (await ref.get()).data();
    // res.questions.push(test);
    res.questions = test;
    await firestore.collection('tests').doc(testId).set(res)
    onSuccess('Test was added successfully');
}

export const addLaw = async (lawQuestion: any) => {
    const ref = await firestore.collection('laws').doc();
    await ref.set(lawQuestion);
}

export const addCertificateB2 = async (cert: any, photoURL:any) => {
    const reff = await firestore.collection('certificatesB2').doc();
    try {
        const ref = storage.ref();
        if (photoURL) {
            const metadata = { contentType: photoURL.type };
            const name = +new Date() + "-" + photoURL.name;
            const task = ref.child(`certificatesB2Request/`).child(`${name}`).put(photoURL, metadata);
            const res = await task.then(snapshot => snapshot.ref.getDownloadURL())
                .then(async (url: string) => url)
                .catch((error: any) => onError("Error creating document: ", error));
                cert.photoURL = res;
        }
        cert.status = "pending";
        await reff.set({ ...cert })
        return cert;
    } catch (error) {
        onError("Error updating document: ", error);
    }
    
    // await ref.set(cert);
}

export const getData = async (uid: string, collection: string) => {
    const doc = await firestore.collection(collection).doc(uid).get();
    return doc?.data();
}

export const getDocs = async (collection: string) => {
    const ref = storage.ref().child(collection)
    const data: any = await ref.listAll()
        .then(async res => res.items.map(async (item) => await item.getDownloadURL().then(async (url: string) => {
            return { url, name: item.name }
        })))
        .catch((error: any) => onError("Error getting document: ", error));
    return Promise.all(data).then((values) => values);
}

export const getUserData = async (uid: string | number) => {
    const ref: any = firestore.collection('users')
    let data = null;
    await ref.where("profileId", "==", +uid).get()
        .then((querySnapshot: any) => { querySnapshot.forEach((doc: any) => { data = doc.data() }) })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const getUsersData = async () => {
    const ref: any = firestore.collection('users')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => { querySnapshot.forEach((doc: any) => { data.push(doc.data()) }) })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const getLatestNews = async (date: any) => {
    const ref: any = firestore.collection('news')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                const _data = doc.data()
                _data.id = doc.id
                data.push(_data)
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const deleteNews = async (news: News) => {
    if (news.img) {
        await deleteFile(news.img)
    }
    await firestore.collection('news').doc(news.id).delete()
    return;
}

export const addNews = async (news: News) => {
    try {
        const ref = storage.ref();
        if (news.img) {
            const metadata = { contentType: news.img.type };
            const name = +new Date() + "-" + news.img.name;
            const task = ref.child(`news/`).child(`${name}`).put(news.img, metadata);
            const res = await task.then(snapshot => snapshot.ref.getDownloadURL())
                .then(async (url: string) => url)
                .catch((error: any) => onError("Error creating document: ", error));
            news.img = res;
        }
        await firestore.collection('news').doc().set({ ...news })
        return news;
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const deleteNewImg = async (news: News) => {
    await deleteFile(news.img)
    news.img = null;
    await updateNews(news)
}

export const updateNews = async (news: News) => {
    if (typeof news.img == 'object') {
        const ref = storage.ref();
        if (news.img) {
            const metadata = { contentType: news.img.type };
            const name = +new Date() + "-" + news.img.name;
            const task = ref.child(`news/`).child(`${name}`).put(news.img, metadata);
            const res = await task.then(snapshot => snapshot.ref.getDownloadURL())
                .then(async (url: string) => url)
                .catch((error: any) => onError("Error creating document: ", error));
            news.img = res;
        }
    }
    try {
        await firestore.collection('news').doc(news.id).update({ ...news })
        return news
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const getLatestUser = async (num: number = 1) => {
    const data: any = [];
    for (let index = 0; index <= num; index++) {
        const refCount = firestore.collection('users').doc('counter');
        const _counter = await refCount.get().then(querySnapshot => querySnapshot.data());
        if (_counter) {
            const a = await getUserData((_counter.count - index))
            if (!a) return data;
            data.push(getUserValuesFromFirebaseUser(a))
        }
    }
    return data;
}

export const getUsers = async () => {
    const ref: any = firestore.collection('users')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                if (!doc.data().count) {
                    data.push(getUserValuesFromFirebaseUser(doc.data()))
                }
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const getCertificates = async () => {
    const ref: any = firestore.collection('certificates')
    const data: any = [];
    await ref.get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc: any) => {
                if (!doc.data().count) {
                    data.push(doc.data())
                }
            })
        })
        .catch((error: any) => onError("Error getting document: ", error));
    return data;
}

export const getStorageDate = async (uid: string, collection: string) => {
    const ref = storage.ref().child(`${uid}/${collection}`)
    return ref.listAll()
        .then((result) => { return result.items.map(async (imageRef) => imageRef.getDownloadURL().then(async (url: string) => url)) })
        .catch((error: any) => onError("Error creating document: ", error));
}

export const deleteFile = async (url: string) => {
    const imageRef = storage.refFromURL(url);
    if (imageRef) {
        await imageRef.delete();
    }
}

export const uploadFile = async (user: User, folderName: string, file: any) => {
    const ref = storage.ref();
    const name = +new Date() + "-" + file.name;
    const metadata = { contentType: file.type };
    const task = ref.child(`${user.profileId}/${folderName}`).child(`${name}`).put(file, metadata);
    let res: any = null;
    if (folderName == 'ava') {
        if (user.photoURL && user.photoURL.length > 0) {
            const imageRef = storage.refFromURL(user.photoURL);
            imageRef.delete();
        }
        res = await task.then(snapshot => snapshot.ref.getDownloadURL())
            .then(async (url: string) => {
                user.photoURL = url;
                await firestore.collection('users').doc(user.uid).set({ ...user });
                return user;
            })
            .catch((error: any) => onError("Error creating document: ", error));
    } else {
        res = await task.then(snapshot => snapshot.ref.getDownloadURL())
            .then(async (url: string) => url)
            .catch((error: any) => onError("Error creating document: ", error));
    }
    return res;
}

export const addCounter = async (uid: string) => {
    let newCount: any = {};
    const ref = firestore.collection('users').doc('counter')
    const _counter = await ref.get().then(querySnapshot => querySnapshot.data())
    if (_counter) {
        newCount = { count: _counter.count + 1 };
        await ref.set(newCount);
    }
    return newCount?.count;
}

export const updateUser = async (uid: string, user: User,) => {
    try {
        const a = await firestore.collection('users').doc(uid).set({ ...user })
    } catch (error) {
        onError("Error updating document: ", error);
    }
}

export const addUser = async (uid: string, user: User,) => {
    try {
        const num = await addCounter(uid);
        user.profileId = num;
        user.create = new Date().getTime()
        user.tabs = [
            {
                name: 'Про_мене',
                data: [{ type: TypeData.File, value: '' }],
                order: 0
            }
        ]
        await firestore.collection('users').doc(uid).set({ ...user });
    } catch (error) {
        onError("Error creating document: ", error);
    }
}