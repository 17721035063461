import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../core/auth";
import { Kyrse } from "../../types/Kyrse";

export type paymentType = "kyrs" | "registration";

type PaymentProps = {
  kyrs: Kyrse | null;
  type: paymentType;
  hidden: boolean
}

export const PaymentKurs = ({ kyrs, type, hidden }: PaymentProps) => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState<string>('');
  const [isPay, setIsPay] = useState<boolean>(false);

// __________________________________________
const isDEMO: boolean = false;
// const isDEMO: boolean = true;
// __________________________________________

  useEffect(() => {
    if (user) {
      if (user.payments) {
        const pay = user.payments.find((r: any) => r?.shopOrderNumber == `${user.uid}-registration`)
        if (pay && pay.status == 'PAYED') {
          setIsPay(true);
        }
      }
    }
  }, [user]);

  const getShortName = (first: any, last: any, sur: any) => {
    const _f = first?.substring(0, 1).toUpperCase();
    const _s = sur?.substring(0, 1).toUpperCase();
    return `${last} ${_f}.${_s}.`
  }

  useEffect(() => {
    
    let url = ``;
    let description = ``;
    let orderNumber = ``;
    let price = ``;
    const payeeId = `${isDEMO ? "25511" : "130059"}`;

    const nameShort = getShortName(user?.firstName, user?.lastName, user?.surName)

    if (type == 'kyrs' && kyrs) {
      url = `https://unapam.com/payment/${user?.uid}-${kyrs.id}`;
      description = `${nameShort} Оплата програми ${kyrs.id}`;
      orderNumber = `${user?.uid}-${kyrs.id}`
      price = `${isPay ? kyrs.priceMembers : kyrs.price}`
    }

    if (type == 'registration') {
      url = `https://unapam.com/payment/${user?.uid}-${type}`;
      description = `${nameShort} Оплата вступного внеску `;
      orderNumber = `${user?.uid}-${type}`
      price = `700`
    }

    const _data = `{"paymentTypes":{"applepay": "Y", "card":"N","portmone":"N","token":"N", "clicktopay":"N","createtokenonly":"N"},
    "priorityPaymentTypes":{"card":"1","portmone":"2", "token":"0","clicktopay":"1","createtokenonly":"0"},
    "payee":{"payeeId":"${payeeId}","login":"","dt":"","signature":"", "shopSiteId":""},
    "order":{"description":"${description}","shopOrderNumber":"${orderNumber}",
             "billAmount":"${price}","attribute2":"${user?.lastName} ${user?.firstName} ${user?.surName}",
             "preauthFlag":"N","billCurrency":"UAH", "encoding":"", "successUrl":"${url}", "failureUrl": "${url}" },
    "token":{"tokenFlag":"N","returnToken":"N","token":"","cardMask":"","otherPaymentMethods":"Y","sellerToken":""},
    "payer":{"lang":"uk", "emailAddress":"${user?.email}"},
    "style":{"type":"light","logo":"","backgroundColorHeader":"", "backgroundColorButtons":"","colorTextAndIcons":"", "borderColorList":"","bcMain":""}
  }`
    setData(_data)
  }, [user, isPay, type, kyrs]);

  return (
    <>
      {data &&
        <form action="https://www.portmone.com.ua/gateway/" method="post" >
          <input type="hidden" name="bodyRequest" value={data} />
          <input type="hidden" name="typeRequest" value='json' />
          <input id={`Pay-${type}`} type="submit" className={`btn btn-primary mе-3 ${user && !hidden ? '' : 'hidden'}`} value="Оплатити курс" />
        </form>
      }
    </>
  )

}
