import swal from 'sweetalert';

export const Modal_Confirm = (isEditing: boolean, i: number, confirm: (value: any, isEdit: any, i: any) => void, text: string, title: string, value: any, type: 'input' | 'text', isEdit: boolean = false) => {

    const input = document.createElement("input");
    input.value = value;
    input.type = 'text';
    input.className = 'swal-content__input';

    const btnCancel = 'Відміна'
    const btnOK = type == 'text' ? 'Видалити' : 'Зберегти'

    swal({
        title: title,
        text: text,
        content: type == 'input' ? input : null,
        icon: type == 'text' ? "warning" : null,
        buttons: [btnCancel, btnOK],
        dangerMode: type == 'text',
    } as any).then((res) => {
        if (res) {
            if (res) {
                confirm(type == 'text' ? value : input.value, i,  isEditing)
            }
        }
    });
}

