import { useContext, useEffect, useState } from "react"
import { addCertificateB2, addLaw } from "../../services/firebase-store.service";
import { NotificationSvc } from "../../components/notification/Notification";
import { AuthContext } from "../../core/auth";
import './certificate-b2.css';

export const CertificatesB2 = () => {
    const { user, setLoading } = useContext(AuthContext);
    const { onSuccess, onInfo } = NotificationSvc();

    const [name, setName] = useState<string>('');
    const [nameEng, setNameEng] = useState<string>('');
    const [phone, setPhone] = useState<number>();
    const [email, setEmail] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [photoURL, setPhotoURL] = useState<string>();
    let upPhoto: any = null;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (user) {
            setName(`${user.lastName} ${user.firstName} ${user.surName}`);
            setEmail(user.email || '')
        }
    }, [user]);

    const sendForm = async (e: any) => {
        e.preventDefault();
        if (photoURL) {
            setLoading(true);
            const _user: any = {};
            if (user) {
                _user.uid = user.uid;
                _user.profileId = user.profileId;
            }
            await addCertificateB2({ phone, name, email, address, nameEng, userUID: _user.uid || '', profileId: _user.profileId || '' }, photoURL);
            onSuccess('Ваша заявка відправлена');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            onInfo('Додайте фото що підтверджує Вашу особу та містить Ваше прізвище та ім’я англійською мовою ');
        }
    }

    const preview = (file: any) => {
        const fr = new FileReader();
        fr.onload = () => {
            const img: any = document.createElement("img");
            img.src = fr.result;
            img.alt = file.name;
            if (document) {
                const doc = document.getElementById('preview-sm');
                if (doc) {
                    doc.innerHTML = "";
                    doc.append(img);

                }
            }
        };
        fr.readAsDataURL(file);
    };

    const uploadImg = async (e: any) => {
        e.stopPropagation()
        preview(e.target.files[0])
        setPhotoURL(e.target.files[0])
    }

    return (
        <div className="mx-4  home">
            <h2> СЕРТИФІКАТ В2 </h2>
            <h4 className="text-center"> відповідно до CEFR (Загальноєвропейські компетенції володіння іноземною мовою)  </h4>

            <h5 className="pt-5">
                ЗАЯВКА ЩОДО ІНФОРМАЦІЇ ПРО СЕРТИФІКАТ В2
            </h5>

            <div className="row mt-3">
                <form onSubmit={sendForm}>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Прізвище, ім’я, по-батькові українською мовою</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="form-control" id="exampleFormControlInput1" placeholder="Прізвище, ім’я, по-батькові українською мовою" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput2" className="form-label">Прізвище ім’я у транслітерації англійською мовою</label>
                        <input type="text" value={nameEng} onChange={(e) => setNameEng(e.target.value)} required className="form-control" id="exampleFormControlInput2" placeholder="Прізвище ім’я у транслітерації англійською мовою" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput4" className="form-label">Е-mail</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" id="exampleFormControlInput4" placeholder="name@example.com" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput3" className="form-label">Телефон</label>
                        <input type="number" value={phone} onChange={(e: any) => setPhone(e.target.value)} required className="form-control" id="exampleFormControlInput3" placeholder="Телефон" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput5" className="form-label">Дані для відправки сертифікату Новою Поштою: місто, номер
                            відділення, ПІП та телефон отримувача</label>
                        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required className="form-control" id="exampleFormControlInput5" placeholder="місто, номер відділення, ПІП та телефон отримувача" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea6" className="form-label">Закордонний паспорт або інший документ, що підтверджує Вашу
                            особу та містить Ваше прізвище та ім’я англійською мовою /скан-копія чи фото першої сторінки</label>

                        <div className="input-group mb-3 ava">
                            <span onClick={() => upPhoto?.click()} className="mt-4" title="Change photo">
                                {
                                    photoURL
                                        ? <div id="preview-sm"></div>
                                        : <span role="button" className="btn btn-danger my-2 m-5"> Додати документ </span>
                                    //  <i style={{ fontSize: '140px' }} className="far fa-file-image"> </i>
                                }
                                <input type="file" ref={(r) => upPhoto = r} id="photo" onChange={(e) => { uploadImg(e) }} />

                            </span>
                        </div>

                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary my-2 m-5"> Відправити </button>
                    </div>
                </form>

                <div className="pt-4">
                    <p>
                        CEFR - це обʼєктивна шкала вимірювання знань європейських мов (англ.- Common
                        European Framework of Reference for Languages), яка має 3 рівні: A — базовий, B —
                        середній, C — просунутий.
                    </p>
                    <p>
                        Сертифікат В2 відповідає Загальноєвропейській рекомендації з мовної освіти з мов
                        країн ЄС для отримання звання доцента та професора (відповідно до Порядку присвоєння
                        вчених звань науковим і науково-педагогічним працівникам, затвердженим наказом МОН
                        від 14.01.2016  № 13 (Джерело: Про затвердження Порядку присвоєння вчених звань
                        науковим і науково-педагогічним працівникам : МОН України; Наказ, Порядок, Перелік [...]
                        від 14.01.2016 № 13. https://zakon.rada.gov.ua/laws/show/z0183-16#Text ).
                    </p>
                    <p>
                        Сертифікат необхідний для вступу до закордонного вишу, отримання стипендії чи
                        гранту на навчання. За наявності сертифікату можна отримати звільнення від іспитів в
                        університеті під час вступу до магістратури або аспірантури.
                    </p>
                    <p>
                        <b>
                            Ви маєте змогу отримати Сертифікат В2, який:
                        </b>
                        <br /> - Визнає МОН для отримання звання доцента та професора
                        <br /> - Відповідає умовам CEFR
                        <br /> - Не потребує додаткового підтвердження
                        <br /> - Не має терміну дійсності (тобто, не потребує перескладання через 3-5-10-..  років)
                        <br /> - У якому зазначено, що Ви пройшли курс підготовки у 180 годин (або 6 кредитів ECTS), що є одночасно підтвердженням підвищення Вашої кваліфікації
                        <br /> - Співвідноситься до Cambridge First Certificate in English FCE Certificate, BEC Vantage, IELTS levels 5-6, TOEFL 87-109, APTIS B2
                        <br /> - Приймають за кордоном (працевлаштування, освіта, гранти тощо)
                        <br /> - Дозволяє отримати звільнення від іспитів в університеті під час вступу в аспірантуру
                    </p>
                    <p>
                        Орієнтовна вартість послуг /за партнерської підтримки установи-резидента ЄС/
                        14 000 грн. (реквізити та детальну інформацію надсилаємо на вказаний Вами емейл після
                        заповнення Заявки).
                        <br />
                        Скан-копії сертифікатів надходять Вам на зазначений у Заявці емейл одразу після
                        видачі сертифікату установою-резидентом ЄС, паперовий формат сертифікату надходить
                        орієнтовно упродовж місяця.
                        <br />
                        Пересилання паперових форматів сертифікатів здійснюється Новою поштою на
                        адресу заявника або його представника/отримувача, якого Ви зазначили у Заявці.
                    </p>
                    <p className="pb-5">
                        <b>Інформація, яка зазначена безпосередньо у сертифікаті:</b>
                        <br /> 1) назва установи, яка видає сертифікат (резидент ЄС), її логотип
                        <br /> 2) Ваше прізвище та ім’я у транслітерації англійською мовою
                        <br /> 3) реєстраційний номер та дата видачі сертифіката
                        <br />  4) обсяг пройденого курсу, а саме 180 годин (6 кредитів ECTS)
                        <br />  5) оцінювання в балах кожного завдання окремо
                        <br />  6) оцінювання загального рівня
                        <br />  7) опис вмінь та навичок, що відповідають рівню В2
                        <br />  8) співвідношення до інших видів іспитів, а саме - Similar to Cambridge First Certificate in English FCE Certificate, BEC Vantage, IELTS levels 5-6, TOEFL 87-109, APTIS B2
                        <br />  9) «живий» підпис (ректора та керівника  підготовчого центру)
                        <br />  10) «мокра» печатка установи, яка видає сертифікат (резидент ЄС)
                        <br />  11) повна назва та адреса установи, яка видала сертифікат (резидент ЄС)
                    </p>
                </div>
            </div>

        </div>

    )
}