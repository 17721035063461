import { useEffect, useRef, useState } from 'react';
import './certificate.css';
import { toPng } from 'html-to-image';
import moment from 'moment';
import back_foto from '.././../asset/certeficate-origin-1.jpg';
import qr_code from '.././../asset/qr-code.png';
import { ua_months } from '../../services/local-data/months';
import { getDateFormat } from '../../helpers/date-format';

const Cerificate = ({ name, course, themes, date, durationHours, durationDays, credits, showSertificate, id, profileId }: any) => {
    const elementRef: any = useRef();
    const _date = new Date(date).getDate()
    const _month = ua_months[new Date(date).getMonth()];
    const _year = new Date(date).getFullYear()
    moment.locale('ua');
    

    const htmlToImageConvert = () => {
        toPng(elementRef.current, { cacheBust: false, fontEmbedCSS: '' })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "certificate.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => console.log(err));
    };
    return (
        
        <div className='p-relative'>
            {showSertificate &&
                <>
                    <div ref={elementRef} className="certificate-main">
                        <img src={back_foto} className="App-logo" alt="logo" />
                        <div className="certificate-content">
                        <img src={qr_code} className="qr-code" alt="logo" />
                            <div className='certificate-title'>С Е Р Т И Ф І К А Т  <div>про підвищення кваліфікації</div> </div>
                            <h3 className="num">№ {id}/{profileId}  <span>від {_date} {_month} {_year} року</span> </h3>
                            <h1><span>засвідчує, що</span> {name.toUpperCase()}</h1>
                            <h3 className="course-text">закінчив(ла) навчальний курс за загальною короткостроковою програмою</h3>
                            <h2>{course}</h2>
                            <h3 className='duration-text left-text'>Тривалість курсу {durationHours} год./ {credits} {credits == 1 ? 'кредит' : credits > 4 ? 'кредитів' : 'кредита'} ECTS</h3>
                            <h3 className='duration-text'>який тривав {getDateFormat(moment(date).subtract(durationDays - 1, "days"))} - {getDateFormat(date)} </h3>
                            <h3 className='thema-text'>
                                {themes.map((theme: any, i: number) => <div key={i}> {theme} </div>)}
                            </h3>
                            <h3 className='sign-1'>Голова Національної асоціації публічного управління та адміністрування України <br /> доктор наук з державного управління, професор Карпа Марта Іванівна</h3>
                            <h3 className='sign-2'>Голова Правління Національної асоціації публічного управління та адміністрування України <br /> кандидат наук з державного управління, доцент Кіцак Тарас Миколайович</h3>
                        </div>
                    </div>
                    <button className="btn btn-primary mt-4" onClick={() => htmlToImageConvert()}>Завантажити сертифікат</button>
                </>
            }
        </div>

    );
}

export default Cerificate;