import { useEffect } from "react"

export const PageContacts = () => {
    useEffect(() => { window.scrollTo(0, 0) }, [])

    return (
        <div className="mx-4 mb-5 home">
            <h1 className="">Контакти</h1>

            <p>
                Громадська організація «Національна асоціація публічного управління та адміністрування України»
            </p>
            <p>
                Ідентифікаційний код юридичної особи: 45123978
            </p>
            <p>
                Місцезнаходження юридичної особи: Україна, 79040, Львівська обл., місто Львів, вул.Городоцька, буд.355/3
            </p>
            <p>
                Голова організації – Карпа Марта Іванівна <br />
                Голова правління – Кіцак Тарас Миколайович
            </p>
            <p>
                www.unapam.com <br />
                E-mail: officeUNAPAM@gmail.com
            </p>

            <p>
                <b> РЕКВІЗИТИ РАХУНКУ </b>
                <br /> №UA253257960000026002300840117 (UAH)
                <br /> Код отримувача 45123978
                <br />  Отримувач платежу ГРОМАДСЬКА ОРГАНІЗАЦІЯ «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ УКРАЇНИ»
                <br /> Банк : Філія Львiвське обласне управління АТ "ОЩАДБАНК"
                <br /> МФО банку  325796
            </p>

            <p>
                При оплаті прохання вказувати свої реквізити (від фізичних осіб – повністю
                прізвище, ім’я, по-батькові) та призначення платежу відповідно до поданої
                таблиці:
            </p>

            <h4 className="text-center">ВИДИ ПЛАТЕЖІВ  ГО «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ»</h4>

            <table className="my-table table table-bordered align-middle table-responsive">
                <thead>
                    <tr>
                        <th className="" >Призначення платежу</th>
                        <th >Сума, грн. </th>
                        <th >Примітка</th>
                    </tr>

                </thead>
                <tbody className="">
                    <tr>
                        <td>Вступний внесок від _______ (вкажіть прізвище, ім’я, по-батькові)</td>
                        <td>700,00</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Добровільна благодійна пожертва</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>За програму/курс/проект _________ (вкажіть назву/код/шифр) від _________ (вкажіть прізвище, ім’я, по-батькові)</td>
                        <td></td>
                        <td>Сума оплати відповідно до вартості програми/курсу/проекту</td>
                    </tr>
                </tbody>
            </table>

            <p>
                Повна назва Організації англійською мовою –Ukrainian National Association of
                Public Administration and Management, NGO.
                Скорочена назва Організації англійською мовою – UNAPAM.
            </p>

            <p> <b>

                IBAN Code: UA253257960000026002300840117 (UAH)
                <br /> Enterprise Code (EDRPOU) 45123978
                <br /> Name Organization: NATIONAL ASSOCIATION OF PUBLIC MANAGEMENT
                <br />AND ADMINISTRATION OF UKRAINE, NGO
                <br /> Address: UA 79040, Lviv, 355/3 Horodotska str.
                <br /> Bank: Branch of the Lviv regional administration of JSC &quot;OSCHADBANK&quot;,
                <br />  MFІ of the bank 325796
                <br />  www.unapam.com
                <br />  E-mail: officeUNAPAM@gmail.com
            </b>
            </p>
        </div >
    )
}