import { useContext, useEffect, useState } from "react"
import { getUsers, getUsersData } from "../../services/firebase-store.service";
import { User } from "../../types/auth";
import { getDateFormat } from "../../helpers/date-format";
import { NavLink } from "react-router-dom";
import { getComitetById, getComitetes } from "../../services/local-data/comitets";
import { AuthContext } from "../../core/auth";

export const Users = () => {
    const [users, setUsers] = useState<[]>([]);
    const { setLoading } = useContext(AuthContext);
    
    useEffect(() => { window.scrollTo(0, 0) }, []);

    useEffect(() => {  getData() }, [])

     const getData = async () => {
        setLoading(true);
        const _users = await getUsers();
        setUsers(_users)
        setLoading(false);
     }

    return (
        <div className="mx-4  home">
            <h2 className="underline">Користувачі</h2>

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">ПІБ</th>
                        <th scope="col">Комітет</th>
                        <th scope="col">Дата реєстрації</th>
                        <th scope="col">Сторінка</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user: User, i:number) => 
                    <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{user.lastName} {user.firstName} {user.surName}</td>
                        <td>{getComitetById(user?.centralComm)?.name}</td>
                        <td>{getDateFormat(user.create)}</td>
                        <td> <NavLink className='nav-link' to={`/user/${user.profileId}`}>Переглянути</NavLink></td>
                    </tr>
                        )
                    }
                </tbody>
            </table>
        </div >
    )
}