import { useContext, useEffect, useState } from "react"
import { getApplications, getLawQuestions, updateApplication, updateLawQuestions } from "../../../services/firebase-admin.service";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { modalIcon } from "../../../components/edit-mode/select-icon";
import { Status } from "../../../types/AdnimMenu";

export const LawAdmin = (props: RouteComponentProps) => {
    const [data, setData] = useState<any>([]);
    // const [dataType, setDataType] = useState<any>([]);
    const { user, changeUser, setLoading } = useContext(AuthContext);

    useEffect(() => {
        user && getData();
    }, [user])

    useEffect(() => {
        const params: any = props.match.params;
      
    }, [props.match.params]);

    const getData = async () => {
        setLoading(true);
        const _data = await getLawQuestions();
        setData(_data);
        setLoading(false);
    }

    const changeStatus = async (status: Status, item: any) => {
        setLoading(true);
        const _item = Object.assign({}, item);
        _item.status = Status[status];
        await updateLawQuestions(_item);
        getData();
    }


    return (
        <div className="p-2">

            <h3>Юридична допомога</h3>
            {/* {modalIcon()} */}

            <table className="table table-dark table-striped apll-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">ПІБ</th>
                        <th scope="col">Пошта</th>
                        <th scope="col">Тема</th>
                        <th scope="col">Текст</th>
                        <th scope="col">Статус</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item: any, i: number) =>
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{item.name}</td>
                                <td>{item.email} </td>
                                <td>{item.theme} </td>
                                <td className="elips-text">{item.text} </td>
                                <td>
                                    {
                                        item.status == Status.pending || !item.status 
                                            ? <div className="status-td">
                                                <i className="fas fa-hourglass-end yellow" />
                                                <div>В&nbsp;процесі</div>
                                            </div>
                                            : item.status == Status.cancel
                                                ? <div className="status-td">
                                                    <i className="fas fa-thumbs-down red" />
                                                    <div>Відхилено</div>
                                                </div>
                                                : <div className="status-td">
                                                    <i className="fas fa-thumbs-up" />
                                                    <div>Підтверджено</div>
                                                </div>

                                    }
                                </td>
                                <td className="edit-icon">
                                    <i className="fas fa-ellipsis-h" />
                                    <div className="edit-body">
                                        {
                                            item.status == Status.pending
                                                ?
                                                <>
                                                    <div onClick={() => changeStatus(Status.approve, item)}> <i className="fas fa-thumbs-up" /> Підтвердити</div>
                                                    <div onClick={() => changeStatus(Status.cancel, item)}><i className="fas fa-thumbs-down red" />  Відхилити</div>
                                                </>
                                                : item.status == Status.approve
                                                    ? <>
                                                        <div onClick={() => changeStatus(Status.cancel, item)}><i className="fas fa-thumbs-down red" />  Відхилити</div>
                                                        <div onClick={() => changeStatus(Status.pending, item)}><i className="fas fa-hourglass-end yellow" />  Скасувати</div>
                                                    </>
                                                    : <>
                                                        <div onClick={() => changeStatus(Status.approve, item)}> <i className="fas fa-thumbs-up" /> Підтвердити</div>
                                                        <div onClick={() => changeStatus(Status.pending, item)}><i className="fas fa-hourglass-end yellow" />  Скасувати</div>
                                                    </>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(LawAdmin);