import { createContext } from "react";
import { User, FirebaseUser} from "../types/auth";
import { firebaseAuth } from "./firebase";
import { addUser, getData } from '../services/firebase-store.service';
import { NotificationSvc } from "../components/notification/Notification";

interface IAuthContext {
  user: User | null | undefined;
  changeUser: (user: User) => void;
  setLoading: (loading: boolean) => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const logout = () => firebaseAuth.signOut();
const { onError } = NotificationSvc();

export const createUser = async (_user: User, email: string, password: string): Promise<User | void> => {
  try {
    const { user } = await firebaseAuth.createUserWithEmailAndPassword(email, password);
    if (user) {
      const AUser = {...user, ..._user};
      const newUsr = new User({ ...AUser, password });
      await addUser((user as FirebaseUser).uid, newUsr);
      return newUsr;
    }
  } catch (error) { onError('', error) }
}

export const loginUser = async (email: string, password: string) => {
  try {
    const { user } = await firebaseAuth.signInWithEmailAndPassword(email, password);
    return await getData((user as FirebaseUser).uid, 'users')
  } catch (error) { onError('', error) }
}




