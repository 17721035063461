import firebase from "firebase";
import { type } from "os";
import { string } from "yup";
import { Status } from "./AdnimMenu";

export type FirebaseUser = firebase.UserInfo;

export type AuthRoles = "basic"  | "user" | "admin" |'publisher';

export enum TypeData {
    None = 0,
    File = 1,
    Text = 2,
    Link = 3,
    PDF = 4,
}

export type TabData = {
    type: TypeData,
    value: string,
    name?: string,
}

export type UserTab = {
    order: number;
    name: string,
    data: TabData[]

}
export class User implements FirebaseUser {
    constructor(props: Partial<User> & { uid: string, password: string }) {
        this.uid = props.uid;
        this.password = props.password;
        if (props.tabs) this.tabs = props.tabs;
        if (props.profileId) this.profileId = props.profileId;
        if (props.firstName && props.lastName) this.displayName = `${props.firstName} ${props.lastName}`;
        if (props.email) this.email = props.email;
        if (props.firstName) this.firstName = props.firstName;
        if (props.lastName) this.lastName = props.lastName;
        if (props.surName) this.surName = props.surName;
        if (props.dateOfBirth) this.dateOfBirth = props.dateOfBirth;
        if (props.ssn) this.ssn = props.ssn;
        if (props.gender) this.gender = props.gender;
        if (props.phoneNumber) this.phoneNumber = props.phoneNumber;
        if (props.photoURL) this.photoURL = props.photoURL;
        if (props.role) this.role = props.role;
        if (props.region) this.region = props.region;
        if (props.district) this.district = props.district;
        if (props.workPlace) this.workPlace = props.workPlace;
        if (props.position) this.position = props.position;
        if (props.centralComm) this.centralComm = props.centralComm;
        if (props.coordinatorNumber) this.coordinatorNumber = props.coordinatorNumber;
        if (props.tests) this.tests = props.tests;
        if (props.tabs) this.tabs = props.tabs;
    }

    tabs: UserTab[] = [];
    tests: any = [];
    create: number = 0;

    role: AuthRoles = 'user';
    profileId: number = 0;
    sign: string = '';
    coordinatorStatus: Status| null = null;
    coordinatorNumber: string | null = null;

    memberCertificateStatus: Status| null = null;

    displayName: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    surName: string | null = null;

    dateOfBirth: string | null = null;
    ssn: string | null = null;
    gender: string | null = null;
    region: string | null = null;
    district: string | null = null;
    workPlace: string | null = null;
    position: string | null = null;
    centralComm: string | null = null;

    contacts: {name: string, value: any, checked: boolean}[] = [];
    email: string | null = null;
    phoneNumber: string | null = null;
    photoURL: string | null = null;
    providerId = '';
    uid: string;
    password = '';
    adminID: string = '';
    categoryType: number = 0;

    payments:any = []

    getValuesToSaveToFirebase() {
        const { displayName, email, phoneNumber, photoURL, providerId, getValuesToSaveToFirebase, ...props } = this;
        return props;
    }
}

export const getUserValuesFromFirebaseUser = (user: User) => {
    const { displayName, firstName, lastName,surName, create, centralComm, email, phoneNumber, photoURL, profileId, uid } = user;
    return { firstName, lastName,surName, create, centralComm ,displayName, email, phoneNumber, photoURL, profileId, uid };
};


