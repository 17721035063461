import { Comitet } from "../../types/Comitets";


const comitets: Comitet[] = [
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ розвитку громад, територій та інфраструктури',
        name: 'Pозвитку громад, територій та інфраструктури',
        value: 1,
        link: ''
    },
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ eкономіки та стратегічних галузей народного господарства',
        name: 'Eкономіки та стратегічних галузей народного господарства',
        value: 2,
        link: ''
    },
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ освіти, науки, культури, молоді та спорту ',
        name: 'Освіти, науки, культури, молоді та спорту ',
        value: 3,
        link: ''
    },
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ охорони здоров’я та соціальної політики',
        name: 'Охорони здоров’я та соціальної політики',
        value: 4,
        link: ''
    },
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ внутрішніх справ та оборони',
        name: 'Внутрішніх справ та оборони',
        value: 5,
        link: ''
    },
    {
        fullName: 'ЦЕНТРАЛЬНИЙ КОМІТЕТ закордонних справ та юстиції',
        name: 'Закордонних справ та юстиції',
        value: 6,
        link: ''
    },

]



export const getComitetes = () => comitets;

export const getComitetById = (id: any) => {
    const c = comitets.find(c => c.value == +id);
     return c
};