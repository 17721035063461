
import { useContext, useEffect, useState } from 'react';
import './modal.css';
import { News } from '../../types/news';
import { deleteFile, deleteNewImg } from '../../services/firebase-store.service';
import { AuthContext } from '../../core/auth';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../Text-editor/Text-editor';



export const Modal_Body = (onSave: any, onCancel: any, data: News | null = null) => {
    const { setLoading } = useContext(AuthContext);
    const [img, setImg] = useState<string | null>(null);
    const [imgLink, setImgLink] = useState<string | null>(null);
    const [textValue, setTextValue] = useState<string | null>(null);
    const [shortTextValue, setShortTextValue] = useState<string | null>(null);
    // const [textValueName, setTextValueName] = useState<string | null>(null);
    const [links, setLinks] = useState<[{ link: string, linkName: string }] | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [id, setId] = useState<string | null>(null);

   

    useEffect(() => {
        if (data) {
            setImg(data.img)
            setImgLink(data.imgLink)
            setTextValue(data.text)
            setShortTextValue(data.shortText)
            setLinks(data.links)
            // setTextValueName(data.linkName)
            // setTextValueLink(data.link)
            setName(data.name)
            setId(data.id)
        }
    }, [data]);

    const onClose = () => {
        setImg(null)
        setImgLink(null)
        setTextValue(null)
        setShortTextValue(null)
        // setTextValueName(null)
        // setTextValueLink(null)
        setName(null)
        setId(null)

        onCancel()
    }

    const urlPatternValidation = (textValue: string) => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        return regex.test(textValue as string);
    };

    const preview = (file: any) => {
        const fr = new FileReader();
        fr.onload = () => {
            const img: any = document.createElement("img");
            img.src = fr.result;
            img.alt = file.name;
            if (document) {
                document.querySelector('#preview')?.append(img);
            }
        };
        fr.readAsDataURL(file);
    };


    const OnAddclick = (id: string) => document.getElementById(id)?.click();

    const addPhoto = (e: any) => {
        preview(e.target.files[0])
        setImg(e.target.files[0])
    }

    const addNews = () => {
        const a = {
            'id': id,
            'shortText': shortTextValue,
            'text': textValue,
            'img': img,
            'imgLink': imgLink,
            'links': links,
            'name': name,
            "date": new Date().getTime()
        }
        onSave(a)
        setTimeout(() => { onClose() }, 1000)
    }

    const deleteImg = async () => {
        setLoading(true)
        const a: any = {
            'id': id,
            'shortText': shortTextValue,
            'text': textValue,
            'img': img,
            'imgLink': imgLink,
            'links': links,
            'name': name,
            "date": new Date().getTime()
        }
        await deleteNewImg(a);
        setImg(null)
        setLoading(false)
    }

    const setLinksValue = (value: string, property: string, i: number) => {
        const _links: any = Object.assign([], links)
        _links[i][property] = value;
        setLinks(_links)
    }

    const addLink = () => {
        const _links: any = Object.assign([], links)
        _links.push({
            link: '',
            linkName: '',
        })
        setLinks(_links)
    }

    return (
        <div className="modal  modal-add-news">
            <div className="modal-content">
                <div className="modal-header">
                    <span className="close" onClick={() => onClose()}>&times;</span>
                    <div className='text-center'>Новини</div>
                </div>
                <div className="modal-body">



                    <div className="input-group my-3">
                        <span className="input-group-text" id="basic-addon2">Назва </span>
                        <input type="text"
                            value={name || ''}
                            className="form-control"
                            placeholder="Назва"
                            aria-describedby="basic-addon2"
                            onChange={e => setName(e.target.value)}
                        />
                    </div>

                   

<div>
   
</div>
                    <div className='modal-body-btn'>

                        {img
                            ? <div className='w-100'>
                                <img src={img} alt="" />
                                <div className='delete-img'>
                                    <i className="fas fa-trash" onClick={() => deleteImg()} title="Видалити" />
                                </div>
                            </div>
                            : <button onClick={(e) => { OnAddclick('img') }} className='btn btn-outline-primary  '>   <i className='fas fa-plus-square'></i> Фото </button>}

                        {imgLink ? null : <button onClick={(e) => { setImgLink(' ') }} className='btn btn-outline-primary  '>   <i className='fas fa-plus-square'></i>Посилання на Фото </button>}
                    </div>

                    <div className='modal-body-img'>
                        {
                            imgLink ?
                                <div className='px-2'>

                                    <div className="input-group mb-5">
                                        <span className="input-group-text" id="basic-addon1">Посилання</span>
                                        <input type="url"
                                            required
                                            value={imgLink || ''}
                                            className={`form-control`}
                                            placeholder="https://example.com"
                                            aria-describedby="basic-addon1"
                                            onChange={e => setImgLink(e.target.value)}
                                        />
                                    </div>
                                    <div className='img-link'  >
                                        <img src={imgLink} alt="" />
                                    </div>


                                </div>
                                : null
                        }

                        <div id="preview"></div>
                    </div>

                    {/* <TextEditor _value={textValue} _change={(v: any) => setTextValue(v)} /> */}
                    <h5>Короткий опис</h5>
                    <div className="input-group mb-3">
                        <textarea
                            required
                            rows={5}
                            value={shortTextValue || ''}
                            className="form-control"
                            aria-describedby="basic-addon1"
                            onChange={e => setShortTextValue(e.target.value)}
                        />
                    </div>
                    <h5>Опис</h5>
                    <div className="input-group mb-3">
                        <textarea
                            required
                            rows={5}
                            value={textValue || ''}
                            className="form-control"
                            aria-describedby="basic-addon1"
                            onChange={e => setTextValue(e.target.value)}
                        />
                    </div>
                    <input accept="image/png, image/jpeg, application/pdf" type="file" id="img" onChange={(e) => { addPhoto(e) }} />
                    <h5>Посилання</h5>
                    {
                        links?.map((link: { link: string, linkName: string, }, i: number) =>
                            <div key={i} className='link-item'>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1">Адреса посилання</span>
                                    <input type="url"
                                        required
                                        value={link.link || ''}
                                        className={`form-control ${urlPatternValidation(`${link.link}`) ? '' : 'invalid-input'}`}
                                        placeholder="https://example.com"
                                        aria-describedby="basic-addon1"
                                        onChange={e => setLinksValue(e.target.value, 'link', i)}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon2">Назва посилання</span>
                                    <input type="text"
                                        value={link.linkName || ''}
                                        className="form-control"
                                        placeholder="name"
                                        aria-describedby="basic-addon2"
                                        onChange={e => setLinksValue(e.target.value, 'linkName', i)}
                                    />
                                </div>
                            </div>

                        )
                    }


                    <button onClick={() => addLink()} className='btn btn-outline-primary  '> 
                      <i className='fas fa-plus-square'></i>Посилання 
                      </button>

                </div>
                <div className="modal-footer">

                    <button onClick={() => onClose()} className="btn btn-danger my-3 ">
                        Скасувати
                    </button>

                    {
                        data?.date
                            ? <button onClick={() => addNews()} className="btn btn-primary my-3 ">
                                Зберегти
                                <i className="fas fa-check mx-1"></i>

                            </button>
                            : <button onClick={() => addNews()} className="btn btn-primary my-3 ">
                                Опублікувати
                                <i className="fas fa-check mx-1"></i>

                            </button>
                    }


                </div>
            </div>
        </div>
    )
}

