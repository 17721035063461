import { useContext, useEffect, useState } from "react"
import { getCertificates } from "../../services/firebase-store.service"
import { getDateFormat } from "../../helpers/date-format";
import { AuthContext } from "../../core/auth";

export const Certificates = () => {
    const [certificates, setCertificates] = useState<[]>([]);
    const { setLoading } = useContext(AuthContext);

    useEffect(() => { window.scrollTo(0, 0) }, [])

    useEffect(() => { getData() }, [])

    const getData = async () => {
        setLoading(true);
        const c = await getCertificates()
        setCertificates(c)
        setLoading(false);
    }

    return (
        <div className="mx-4  home">
            <h2 className="underline">Сертифікати</h2>

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">ПІБ</th>
                        <th scope="col">Опис</th>
                        <th scope="col">Теми</th>
                        <th scope="col">Номер</th>
                        <th scope="col">К-сть, строки</th>
                        <th scope="col">Дата</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        certificates?.map((certificate: any, i: number) => (
                            certificate.certificates.map((cert: any, ii: number) =>
                                <tr key={`${i}-${ii}`}>
                                    <td>{cert.name}</td>
                                    <td>{cert.course}</td>
                                    <td style={{lineHeight: '1.2'}}>
                                        {cert.themes.map((th: any, iii: number) =>
                                            <div key={iii}>{th}</div>)}
                                    </td>
                                    <td>{cert.id}</td>
                                    <td>{cert.credits} кредит ЄКТС/{cert.durationHours} годин </td>
                                    <td>{getDateFormat(new Date(cert.date))}</td>
                                </tr>
                            )
                        ))
                    }
                </tbody>
            </table>
        </div >
    )
}