import { useEffect, useRef, useState } from 'react';
import './google_slider.css';

import ReactGoogleSlides from "react-google-slides";

const GoogleSlider = ({ width, height, url }: any) => {
   
    return (
        <ReactGoogleSlides
          width={width}
          height={height}
          slidesLink={`${url}`}
        //   slideDuration={5}
          position={1}
          showControls
          loop
        />
      );
}

export default GoogleSlider;