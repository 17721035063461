export const PaymentTable = ({ payments }: any) => {

    return (
        <table className="table table-bordered ">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Опис</th>
                    <th scope="col">Номер</th>
                    <th scope="col">Сума (грн.)</th>
                    <th scope="col">Статус</th>

                </tr>
            </thead>
            <tbody>
                {
                    payments?.map((pay: any, i: number) => (
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{pay.description}</td>
                            <td>{pay.shopOrderNumber}</td>
                            <td>{pay.billAmount}</td>
                            <td>{pay.status}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )

}