import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAxzcQWBxJsK5X_pX0Yq3VORp6-nC6IUko",
  authDomain: "web-unapam.firebaseapp.com",
  databaseURL: "https://web-unapam-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "web-unapam",
  storageBucket: "web-unapam.appspot.com",
  messagingSenderId: "430233021241",
  appId: "1:430233021241:web:1a3b68d576223af40056e8",
  measurementId: "G-1BGM0QB3E3"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunc = firebaseApp.functions();
export const firestore = firebaseApp.firestore();
export const storage = firebase.storage();
