// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import  './base.css';
import {App} from './App';
import * as Yup from "yup";
import yupPass from 'yup-password';
import React from 'react';
yupPass(Yup);
const doc: any = document.getElementById("root")
const root = ReactDOM.createRoot(doc);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// ReactDOM.render(<App />, document.getElementById('root'));
