import { useContext, useEffect, useState } from 'react';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { AuthContext, loginUser } from '../../core/auth';
import { User } from '../../types/auth';
import './login-form.css';
import bgPhoto from '../../asset/logo.black.png';
import { RegistrationForm } from '../../pages/RegistrationForm/Registration-form';

export const LoginForm = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const { user, changeUser } = useContext(AuthContext);

    useEffect(() => {
        (() => {
            'use strict';
            const forms = document.querySelectorAll('.needs-validation');
            Array.prototype.slice.call(forms).forEach((form) => {
                form.addEventListener('submit', (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
                    if (!form.checkValidity()) {
                        event.preventDefault(); event.stopPropagation();
                    } form.classList.add('was-validated')
                }, false);
            });
        })();
    }, [])

    const login = (e: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        loginUser(email, password).then(_user => _user && changeUser(_user as User))
    }

    const clickRegister = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e?.preventDefault();
        setIsLogin(false);
        const a = document.getElementById('tab-register');
        a && a.click();
    }

    if (user && isLogin) return <Redirect to="/" />;

    return (
        
        <section className=" p-4 d-flex justify-content-center pb-4">

            <div className="login-form">
                <NavLink to="/" className='close-btn'>
                    <i className='fas fa-times-circle'></i>
                </NavLink>
                <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <div className={`nav-link ${isLogin ? 'active' : ''}`}
                            id="tab-login"
                            data-mdb-toggle="pill"
                            // href="#pills-login"
                            role="tab"
                            aria-controls="pills-login"
                            aria-selected="true"
                            onClick={() => setIsLogin(true)}
                        >Вхід</div>
                    </li>
                    <li className="nav-item" role="presentation">
                    <div className={`nav-link ${isLogin ? '' : 'active'}`}
                            // aria-disabled="true"
                            id="tab-register"
                            data-mdb-toggle="pill"
                            // href="#pills-register"
                            role="tab"
                            aria-controls="pills-register"
                            aria-selected="false"
                            onClick={(e: any) => {setIsLogin(false)
                               clickRegister(e)
                            }}
                        >Реєстрація</div>
                    </li>
                </ul>

                <div className="tab-content">
                {
                        isLogin ? 
                    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">

                        {/* ________________________FORM */}
                        <form className='main-login-form needs-validation' onSubmit={e => login(e)} noValidate >

                            <div className=" mb-4">
                                <label className="form-label" htmlFor="loginName">Пошта</label>
                                <input required
                                    type="email"
                                    id="loginName"
                                    className="form-control"
                                    value={email || ''}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label" htmlFor="loginPassword">Пароль</label>
                                <input required
                                    type="password"
                                    id="loginPassword"
                                    className="form-control"
                                    value={password || ''}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>

                            <div className="row mt-4 mb-4">
                                {/* <div className="row mb-4">
                                <div className="col-md-6 d-flex justify-content-center">
                                    <div className="form-check mb-3 mb-md-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="loginCheck"
                                            checked
                                        />
                                        <label className="form-check-label" htmlFor="loginCheck"> Remember me </label>
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex justify-content-center">
                                    <a href="/forgotpassword">Forgot password?</a>
                                </div>
                            </div> */}
                            </div>

                            <button type="submit" className="btn btn-primary btn-block mb-4">Увійти</button>

                            <div className="text-center">
                                <p>Not a member? <a href="/login" onClick={(e) => clickRegister(e)}>Register</a></p>
                            </div>
                        </form>

                        <div className='text-center'>
                            <img src={bgPhoto} alt="" className='logo-background' />
                        </div>
                    </div>
                  
                        : <RegistrationForm />
                   
                    }
                  
                </div>
            </div>
        </section>
    )
}

export default withRouter(LoginForm);