import { useContext, useEffect, useState } from "react";
import { getDateFormat } from "../../helpers/date-format"
import { News } from "../../types/news"
import { AuthContext } from "../../core/auth";
import { deleteNews, getLatestNews, updateNews } from "../../services/firebase-store.service";
import './news.css'
import { RouteComponentProps } from "react-router-dom";
import { Modal_Body } from "../../components/modals/modal-body";
import { Modal_Confirm } from "../../components/modals/modal";
import ReactQuill from "react-quill";

export const NewsPage = (props: RouteComponentProps) => {
    const [_news, setNews] = useState<any[]>([]);
    const { setLoading, user } = useContext(AuthContext);
    const [newId, setNewId] = useState<string>('');
    const [editNews, setEditNews] = useState<News | null>(null);

    useEffect(() => { window.scrollTo(0, 0) }, []);
    useEffect(() => { getInfo() }, []);

    const getInfo = async () => {
        setLoading(true);
        const a = await getLatestNews(new Date().getTime())
        a.sort((a: News, b: News) => +b.date - +a.date)
        setNews(a)
        setLoading(false);
    }

    useEffect(() => {
        const params: any = props.match.params;
        if (params && params.id) {
            setLoading(true);
            setNewId(params.id);
            setTimeout(() => {
                scrollTo(+params.id);
                setLoading(false);
            }, 1000);
        }
    }, []);


    const scrollTo = (id: number) => {
        const a: any = document.getElementById(`${id}`)
        if (a) {
            window.scroll(0, findPosition(a));
        }
    }

    const findPosition = (obj: any) => {
        let currenttop: any = 0;
        if (obj.offsetParent) {
            do {
                currenttop += obj.offsetTop;
            } while ((obj = obj.offsetParent));
            return currenttop;
        }
    }

    const onAddNews = async (a: any) => {
        setLoading(true);
        await updateNews(a)
        getInfo()
        setLoading(false);
    }

    const onCancelNews = () => {
        document.body.style.overflow = 'visible';
        setEditNews(null);
    }

    const delNews = async (a: any) => {
        setLoading(true);
        await deleteNews(a);
        getInfo()
        setLoading(false);
    }

    const onDeleteNews = (a: any) => {
        Modal_Confirm(false, 0, delNews, `Ви впевнені що хочете видалити цю новину?`, 'Confirm', a, 'text');
    }

    const a = Modal_Body(onAddNews, onCancelNews, editNews as News)

    return (<div className="mx-4 home  news-page">

        <div className="contaiter pt-4">
            <div className="row">
                <h2>НОВИНИ, ФАКТИ</h2>
                {editNews ? a : null}
                {
                    _news.map((news: News, i: number) =>
                        <div key={i} id={news.date} className="new-item-body ">
                            <div className="pl-2  date sub-title">
                                Дата:      {getDateFormat(new Date(news.date))}
                            </div>
                            {
                                user?.role == "publisher" &&
                                <div className="edit-btn">
                                    <i className="fas fa-trash" onClick={() => onDeleteNews(news)} title="Видалити" />
                                    <i className="fas fa-edit" onClick={() => setEditNews(news)} title="Редагування" />
                                </div>
                            }
                            <div className='new-item-name  mb-3'>
                                {news?.name}
                            </div>
                            <div className='sub-title'>
                                <pre>
                                    <b>
                                        {news?.shortText}
                                        <br />
                                        <br />
                                        {news?.text}
                                    {/* <div dangerouslySetInnerHTML={{ __html: news?.text }} /> */}
                                        {/* {news?.text} */}
                                        {/* <ReactQuill
                                            readOnly={true}
                                            theme={"bubble"}
                                            value={news?.text}

                                        /> */}

                                    </b>
                                </pre>
                            </div>
                            <img src={news?.imgLink} alt="" />
                            <img src={news?.img} alt="" />

                            <div className="pt-3">
                                {
                                    news?.links?.map((link: { link: string, linkName: string, }, i: number) =>
                                        <a key={i} target="_blank" href={link.link}>{link.linkName ? link.linkName : link.link}</a>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    </div>)
}