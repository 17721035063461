import { NavLink } from "react-router-dom"


export const Footer = () => {
    return (
        <footer className="text-center text-lg-start text-white">
            <div className="container p-4 pt-5 pb-0 ">
                <section className="">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold">
                                НАПУАУ
                            </h6>
                            <p>
                                Громадська організація «Національна асоціація публічного управління та адміністрування України»
                            </p>
                        </div>


                        <hr className="w-100 clearfix d-md-none" />

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold">
                                Посилання
                            </h6>
                            <p>
                                <NavLink className='text-white' to="/upravlinya">Органи управління</NavLink>
                            </p>
                            <p>
                                <NavLink className='text-white' to="/pochesni">Почесні члени</NavLink>
                            </p>
                            <p>
                                <NavLink className='text-white' to="/kyrsu">Навчальні курси</NavLink>
                            </p>
                            <p>
                                <NavLink className='text-white' to="/contacts">Контакти</NavLink>
                            </p>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold">Контакти</h6>
                            <p><i className="fas fa-home mr-3"></i> вул. Городоцька буд. 355/3 м. Львів Україна, 79040</p>
                            <p><i className="fas fa-envelope mr-3"></i>
                                <a className='text-white' href="mailto:officeUNAPAM@gmail.com"> officeUNAPAM@gmail.com</a>

                            </p>
                            {/* <p><i className="fas fa-phone mr-3"></i> 
                            <a className='text-white' href="tel:+ 01 234 567 89"> + 01 234 567 89</a>
                            </p>

                            <p><i className="fas fa-fax mr-3"></i>  + 01 234 567 89</p> */}
                        </div>
                    </div>
                </section>

                <hr className="my-3" />

                <section className="p-3 ">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-8 text-center text-md-start">
                            <div className="p-3">
                                © {new Date().getFullYear()} Copyright: НАПУАУ
                            </div>
                        </div>

                        <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                            <a href="https://www.facebook.com/profile.php?id=61555309911496" target="_blank" className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            {/* <a className="btn btn-outline-light btn-floating m-1 " role="button">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
            <i className="fab fa-google"></i>
          </a> */}
                            <a href="https://www.instagram.com/unapam_go?igsh=MWh1dHFndm5yemNoNA==" className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </footer>

    )
}