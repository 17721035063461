
import './infobox.css';

export const InfoBox = ({text, link, position}: any) => {

    return (
        <div className='box-item'>
            <div className={`success box ${position}`}>
               {text} 
              {link &&  <a href={link.value}> {link.name}</a> }
            </div>
        </div>
    )
}