import { useContext, useEffect, useState } from 'react';
import './info.css';
import { AuthContext } from '../../core/auth';
import { NotificationSvc } from '../../components/notification/Notification';
import TextEditor from '../../components/Text-editor/Text-editor';
import ReactQuill from "react-quill";
import { addInfo, getInfo, updateInfo } from '../../services/firebase-store.service';
import { RouteComponentProps } from 'react-router-dom';
import { Modal_Confirm } from '../../components/modals/modal';

export const InfoPage = (props: RouteComponentProps) => {
    const { user, setLoading } = useContext(AuthContext);
    const [val, setVal] = useState<string>('');
    const [adding, setAdding] = useState<boolean>(false);
    const [editValue, setEditvalue] = useState<string>('');
    const [info, setInfo] = useState<any>(null);
    const [infoId, setInfoId] = useState<any>(null);
    const { onSuccess, onInfo } = NotificationSvc();

    // useEffect(() => { window.scrollTo(0, 0) }, []);

    useEffect(() => {
        const params: any = props.match.params;
        if (params)
            setInfoId(params.id);
        setAdding(false);
        setEditvalue('');
        getData(params.id);
        window.scrollTo(0, 0);
    }, [props.match.params]);


    const getData = async (params: string) => {
        const _info = await getInfo(params);
        setInfo(_info);
    }

    const ch = (e: any) => {
        setVal(e);
    }

    const onAddInfo = async () => {
        if (val && val.length > 0) {
            const _i = info?.value || []
            await addInfo({ name: info.name, value: [val, ..._i] }, infoId);
            onSuccess('Інформацію додано успішно');
            setAdding(false);
            setVal('');
            const params: any = props.match.params;
            getData(params.id)
        } else {
            onInfo('Відсутній текст')
        }
    }

    const deleteInfo = async (el: any, i: number) => {
        const _info: any = Object.assign({}, info);
        _info.value.splice(i, 1);
        await updateInfo(_info, infoId);
        getData(infoId);

    }
    const onDelete = (el: any, i: number) => {
        Modal_Confirm(false, i, deleteInfo, `Ви впевнені що хочете видалити цю інформацію?`, 'Confirm', el, 'text');
    }

    const onEdit = (el: any, i: number) => {
        setEditvalue(el)
        setVal(el)
    }

    const onUpdate = async () => {
        const i = info.value.findIndex((el: string) => el == editValue);
        if (i >= 0) {
            const _info: any = Object.assign({}, info);
            _info.value[i] = val;
            await updateInfo(_info, infoId);
            onSuccess('Інформація оновлена успішно');
            setEditvalue('');
            getData(infoId);
        }
    }

    const cancel = () => {
        setEditvalue('');
        setAdding(false);
        setVal('');
    }

    return (
        <div className="mx-4  home ">
            <h2>{info?.name}</h2>
            {
                user?.role == 'publisher' || user?.role == 'admin' ?
                    <div>
                        {adding || editValue
                            ? <div>
                                <div>
                                    <TextEditor _change={ch} _value={val} />
                                </div>
                                <div className='text-right'>
                                    <button className="btn btn-danger mx-3 mt-3" onClick={() => cancel()}>Відміна</button>
                                    <button className="btn btn-primary mx-3 mt-3" onClick={() => { editValue ? onUpdate() : onAddInfo() }}>Зберегти</button>
                                </div>

                                <div className='mt-5 info-i'>
                                    <div dangerouslySetInnerHTML={{ __html: val }} />
                                </div>
                            </div>
                            : <div className='text-right'>
                                <button className="btn btn-primary mx-3 my-3" onClick={() => setAdding(!adding)}>ДОДАТИ</button>
                            </div>
                        }
                    </div>
                    : null
            }
            {
                !adding && !editValue &&
                <div>
                    {info && info.value.map((el: any, i: number) =>
                        el && <div key={i} className='info-i'>
                            {
                                user?.role == 'publisher' || user?.role == 'admin' ?
                                    <div className="edit-btn">
                                        <i className="fas fa-trash" onClick={() => onDelete(el, i)} title="Видалити" />
                                        <i className="fas fa-edit" onClick={() => onEdit(el, i)} title="Редагування" />
                                    </div>
                                    : null
                            }
                            <div dangerouslySetInnerHTML={{ __html: el }} />
                        </div>
                    )}
                </div>
            }
        </div>

    )
}