import { useEffect, useState } from "react"
import './dashboard.css';
import { NavLink, withRouter } from "react-router-dom";
import { Chart1 } from "../../components/Chart/Chart";
import { Example2 } from "../../components/Chart/Chart3";
import { Example } from "../../components/Chart/Chart2";




const Dashboard = (props: any) => {
    const [activeTab, setActiveTab] = useState<string>('-1');
    const [renderData, setRenderData] = useState<any>(null);


    useEffect(() => { window.scrollTo(0, 0) }, [])
  

    useEffect(() => {
        document.getElementById('dismiss')?.addEventListener('click', (e: any) => {
            const navbar: any = document.getElementById('sidenav')
            navbar.classList.remove('open');
        })
    }, [])

    const showMenu = () => {
        const menu: any = document.querySelector('.menu')
        menu && menu.classList.toggle('open');
    }

    const onSetData = (component: any) => {
        // setRenderData(component)
    }


    return (
        // <div>
        <>
<Chart1/>
        <Example2/>
        </>
        // </div>
     
    )
}

export default withRouter(Dashboard);