import { useContext, useEffect, useState } from "react"
import { addLaw } from "../../services/firebase-store.service";
import { NotificationSvc } from "../../components/notification/Notification";
import { AuthContext } from "../../core/auth";

export const LawPage = () => {
    const { user, setLoading } = useContext(AuthContext);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [theme, setTheme] = useState<string>('');
    const [text, setText] = useState<string>('');
    const { onSuccess } = NotificationSvc();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (user) {
            setName(`${user.lastName} ${user.firstName} ${user.surName}`);
            setEmail(user.email || '')
        }
    }, [user]);

    const sendForm = (e: any) => {
        e.preventDefault();
        setLoading(true);
        const _user: any = {};
        if (user) {
            _user.uid = user.uid;
            _user.profileId = user.profileId;
        }
        addLaw({ name, email, theme, text, user: _user });
        onSuccess('Ваша заявка відправлена');
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    return (
        <div className="mx-4  home">
            <h2>Правнича допомога</h2>

            <div className="row mt-3">
                <form onSubmit={sendForm}>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Прізвище, Ім'я</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required className="form-control" id="exampleFormControlInput1" placeholder="Прізвище, Ім'я" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Ваша пошта</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Тема</label>
                        <input type="text" value={theme} onChange={(e) => setTheme(e.target.value)} required className="form-control" id="exampleFormControlInput1" placeholder="Тема" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Текст</label>
                        <textarea value={text} onChange={(e) => setText(e.target.value)} required className="form-control" id="exampleFormControlTextarea1" rows={5}></textarea>
                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary my-3 m-5"> Відправити </button>
                    </div>
                </form>
            </div>

        </div>

    )
}