import { getDateFormat } from "../../helpers/date-format";
import Cerificate from "../certificate/certificate";

export const CertificateTable = ({ certificates }: any) => {

    return (
        <div className="table-cert">

            <table className="table table-bordered ">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Опис</th>
                        <th scope="col">Дата</th>
                        <th scope="col">-</th>


                    </tr>
                </thead>
                <tbody>
                    {
                        certificates?.map((cert: any, i: number) => (
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{cert.course}</td>
                                <td>{getDateFormat(new Date(cert.date))}</td>

                                <td className="td-table-certificate">
                                    <i className="fas fa-eye"></i>
                                    <div className="table-certificate">

                                        <Cerificate
                                            name={`${cert.name}`}
                                            profileId={cert?.profileId}
                                            course={cert.course}
                                            themes={cert.themes}
                                            id={cert.id}
                                            durationHours={cert.durationHours}
                                            durationDays={cert.durationDays}
                                            date={new Date(cert.date as string)}
                                            credits={cert.credits}
                                            showSertificate={true}
                                        />
                                    </div>



                                </td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )

}