

const regions = [
    { name: 'Автономна Республіка Крим' },
    { name: 'Вінницька' },
    { name: 'Волинська' },
    { name: 'Дніпропетровська' },
    { name: 'Донецька' },
    { name: 'Житомирська' },
    { name: 'Запорізька' },
    { name: 'Закарпатська' },
    { name: 'Івано-Франківська' },
    { name: 'Київська' },
    { name: 'Кіровоградська' },
    { name: 'Луганська' },
    { name: 'Львівська' },
    { name: 'Миколаївська' },
    { name: 'Одеська' },
    { name: 'Полтавська' },
    { name: 'Рівненська' },
    { name: 'Сумська' },
    { name: 'Тернопільська' },
    { name: 'Харківська' },
    { name: 'Херсонська' },
    { name: 'Хмельницька' },
    { name: 'Черкаська' },
    { name: 'Чернівецька' },
    { name: 'Чернігівська' },
]

export const getRegions = () => regions

