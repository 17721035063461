import { NavLink, RouteComponentProps, withRouter } from "react-router-dom"
import Navbar from "../navigation/navbar"
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, logout } from "../../core/auth";
import ScrollOut from "scroll-out";
import Logo from "../../asset/logo.png";
import '../navigation/navbar.css'
import { getUsersData } from "../../services/firebase-store.service";
import { User } from "../../types/auth";
import { PaymentKurs } from "../payments/payment_kurs";


const Header: FC<RouteComponentProps> = ({ history }) => {
    const { user } = useContext(AuthContext);
    const [filterData, setFilterData] = useState<User[]>([]);
    const [data, setData] = useState<User[]>([]);
    const [isPay, setIsPay] = useState<boolean>(false);

    useEffect(() => { ScrollOut({ cssProps: true, threshold: 0.5 }); }, []);
    useEffect(() => { getUsersData().then(res => setData(res)); }, []);

    const onLogout = useCallback(() => void logout().then(() => {
        history.push('/login')
        window.location.replace('/login')
    }), [history]);

    useEffect(() => {
        if (user) {
            if (user.payments) {
                const pay = user.payments.find((r: any) => r?.shopOrderNumber == `${user.uid}-registration`)
                if (pay && pay.status == 'PAYED') {
                    setIsPay(true);
                }
            }
        }
    }, [user]);

    const onSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();

        if (value == null || value == '') {
            setFilterData([]);
        } else {
            const _data = data.filter((name: any) => {
                if (name && name.firstName) {
                    if ((name.firstName).toLowerCase().includes(value) ||
                        (name.lastName).toLowerCase().includes(value)
                        // ||(name.region).toLowerCase().includes(value)
                    ) { return name }
                }
            })
            setFilterData(_data);
        }
    }

    const createPayment = () => {
        const a = document.getElementById('Pay-registration');
        a && a.click();
    }

    return (

        <header className="header" data-scroll>
            <div className="height-0" >
              <PaymentKurs kyrs={null} type={'registration'} hidden={true}/>
            </div>
            <div className="container-header">

                <NavLink className="logo" to="/">
                    <img src={Logo} />
                </NavLink>

                <span>вул. Городоцька буд. 355/3
                    < br /> м. Львів Україна, 79040
                    <br /> officeUNAPAM@gmail.com
                </span>
                {/* <span>officeUNAPAM@gmail.com</span> */}

                <div className="search-section">

                    <div className="input-group">
                        <div id="search-autocomplete" className="form-outline">
                            <input type="search" placeholder="Search" onChange={e => onSearch(e)} id="form1" className="form-control" />
                        </div>
                        <button type="button" className="btn btn-primary">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>

                    <div className="search-result">
                        {
                            filterData.map((user: User, i: number) =>
                                <div key={i} onClick={() => window.location.reload()}>
                                    <NavLink to={`/user/${user.profileId}`}>
                                        {user.firstName} {user.lastName} {'  '}
                                        <span>({user.region} {'обл.'})</span>
                                    </NavLink>
                                </div>
                            )
                        }
                    </div>

                </div>
                {
                    user
                        ? <div className="user-name">
                            <span style={{ fontSize: '12px', paddingRight: '10px' }}>{user?.firstName} {user.lastName} </span>
                            {user.photoURL
                                ? <img style={{ width: '50px' }} src={`${user.photoURL}`}></img>
                                : <i style={{ fontSize: '18px' }} className="far fa-user"> </i>
                            }

                            <div className="dropdown-menu user-menu" aria-labelledby="navbarDropdown">
                                {
                                    // user?.role == "basic"
                                    isPay
                                        ? <NavLink className={(isActive) => (isActive ? 'active dropdown-item' : 'dropdown-item')} to="/profile">Особистий кабінет</NavLink>
                                        :  <button className="dropdown-item" onClick={() => createPayment()}>Сплатити членський внесок</button>
                                }
                                {/* <div className="dropdown-divider"></div> */}
                                <button className="dropdown-item" onClick={() => onLogout()}>Вийти</button>
                            </div>
                        </div>
                        :
                        <button type="button" onClick={() => onLogout()} className="btn btn-ent">Реєстрація / Вхід</button>
                }
            </div>
            <Navbar />
        </header>
    )
}
export default withRouter(Header);
