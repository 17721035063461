import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import { getComitetes } from "../../services/local-data/comitets";
import { Comitet } from "../../types/Comitets";


const Navbar = () => {

  const comitets = getComitetes()

  useEffect(() => {
    const navbar: any = document.querySelector('.navbar')
    navbar.querySelector('.toggle').addEventListener('click', () => {
      navbar.classList.toggle('collapsed')
      const header: any = document.querySelector('.header');
      header && header.classList.toggle('nostyle');
    })

    document.querySelectorAll('.dropdown-menu > a').forEach((el: any) => el.addEventListener('click', () => hideMenu(navbar)))
    document.querySelectorAll(' li > a:not(.dropdown-toggle)').forEach((el: any) => el.addEventListener('click', () => hideMenu(navbar)))

    window.addEventListener('scroll', (e) => {
      const windowY = window.scrollY;
      const nHeight: any = document.querySelector('.navbar');
      const navbarHeight = nHeight?.navbarHeight;
      if (windowY > navbarHeight) navbar.classList.add('sticky');
      else navbar.classList.remove('sticky');
    })
  }, []);

  const hideMenu = (navbar: any) => {
    if (navbar.classList.contains('collapsed')) {
      navbar.classList.toggle('collapsed')
    }
  }

  return (
    <div className="navbar">

      <ul className="links">
        {/* <li className="nav-item active">
          <NavLink className='nav-link' to="/">Про&nbsp;нас</NavLink>
        </li> */}

        <li className="nav-item dropdown ">
        {/* <NavLink className='nav-link dropdown-toggle active' to="/">Про&nbsp;нас</NavLink> */}
          <a className="nav-link dropdown-toggle active" href="/" >
          Про&nbsp;нас
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <NavLink className='nav-link active' to="/">Головна</NavLink>
          <NavLink className='nav-link' to="/upravlinya">Органи управління</NavLink>
          <NavLink className='nav-link' to="/structura">Структура</NavLink>
          <NavLink className='nav-link' to="/contacts">Контакти та реквізити</NavLink>
          </div>
        </li>


        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/upravlinya">Органи управління</NavLink>
        </li> */}
        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/pochesni">Почесні члени</NavLink>
        </li> */}
       
        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/structura">Структура</NavLink>
        </li> */}
        {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" >
            Форуми
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {
              comitets.map((comitet: Comitet, i: number) =>
                <NavLink key={i} className='nav-link' to={comitet.link}>{comitet.fullName} </NavLink>
              )
            }
          </div>
        </li> */}
     
     <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" >
            Послуги
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <NavLink className='nav-link' to="/kyrsu">Програми підвищення кваліфікації </NavLink>
          <NavLink className='nav-link' to='/certificates-b2'> Сертифікат щодо володіння іноземною мовою (рівень В2) </NavLink>
          <NavLink className='nav-link' to='/'>  Стажування  </NavLink>
          <NavLink className='nav-link' to="/law">Правнича допомога</NavLink>
          </div>
        </li>
        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/kyrsu">Програми</NavLink>
        </li> */}
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" >
            Реєстри
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <NavLink className='nav-link' to='/users'>Користувачі </NavLink>
          <NavLink className='nav-link' to='/certificates'>Сертифікати </NavLink>
          </div>
        </li>
        <li className="nav-item">
          <NavLink className='nav-link' to="/news">Новини, факти</NavLink>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" >
          Корисна інформація
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <NavLink className='nav-link' to='/info/1'>Наукові фахові видання з публічного управління  </NavLink>
          <NavLink className='nav-link' to='/info/2'>Міжнародні програми, гранти </NavLink>
          <NavLink className='nav-link' to='/info/3'>Навчання за спеціальністю 281 «Публічне управління та адміністрування» </NavLink>
          <NavLink className='nav-link' to='/info/4'>Працевлаштування  </NavLink>
          </div>
        </li>
        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/info">Корисна інформація</NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink className='nav-link' to="/questions">Запитання-відповіді</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink className='nav-link' to="/law">Правнича допомога</NavLink>
        </li> */}
      </ul>

      <div className="toggle">
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>

      <div className=" d-flex align-items-center  social-container">


        <div className="col-md-12 col-lg-12 ml-lg-12 text-center text-md-end social-icons">
          <a href="https://www.facebook.com/profile.php?id=61555309911496" target="_blank" className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
            <i className="fab fa-facebook-f"></i>
          </a>
          {/* <a className="btn btn-outline-light btn-floating m-1 " role="button">
            <i className="fab fa-twitter"></i>
          </a>
          <a className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
            <i className="fab fa-google"></i>
          </a> */}
          <a href="https://www.instagram.com/unapam_go?igsh=MWh1dHFndm5yemNoNA=="  className="btn btn-outline-light btn-floating m-1 text-whit" role="button">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>

    </div>
  )
};

export default Navbar;