import { UseFetch } from "../hooks/useFetch";
import { User } from "../types/auth";

export const DataService = () => {

    // const baseUrl = "http://127.0.0.1:5001/web-unapam/us-central1/api";
    const baseUrl = "https://us-central1-web-unapam.cloudfunctions.net/api";


    const paid = async (payId: string, user:any) => {
        const { makeRequest } = UseFetch(`${baseUrl}/paid`);
        try {
            const res = await makeRequest({ method: "POST", body: { payId ,user} });
            return res;
        } catch (err) {

        }
    }

    const resultTest = async (testId: string, answers: any) => {
        const { _makeRequest } = UseFetch(`${baseUrl}/testresult`);
        return _makeRequest({ method: "POST", body: { testId, answers} })
        .then(response => response.json())
    }
    return { paid, resultTest }
}
