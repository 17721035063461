export const Questions = () => {



    return (
        <div className="mx-4 mb-5  home">
            <h2>Запитання - відповіді</h2>
            <p>
                Пропонуємо ознайомитися з відповідями на найбільш поширені запитання
            </p>
           <b> ХТО МОЖЕ СТАТИ ЧЛЕНОМ ОРГАНІЗАЦІЇ?</b>
            <br /> Членство в Організації є добровільним та індивідуальним.
            Членами Організації можуть бути громадяни України, іноземці та особи без громадянства, які
            перебувають в Україні на законних підставах, які досягли 14 років і які визнають Статут Організації та
            сприяють діяльності, що спрямована на досягнення мети і завдань Організації.
            Приналежність до сфери публічного управління та адміністрування не є обов’язковою, але вітається.
            <br />  <br /> <b> ЯКІ ПРОФЕСІЙНІ СФЕРИ ОХОПЛЮЄ ОРГАНІЗАЦІЯ?</b>
            <br /> - державні службовці
            <br />  - посадові особи органів місцевого самоврядування
            <br /> - управлінці бізнес-сфер, зокрема ті, які залучені у сферу публічних фінансів, державно-
            приватного партнерства, кадрового забезпечення тощо
            <br />  - науковці галузей знань публічного управління та адміністрування, економіки, права,
            освіти/педагогіки, міжнародних відносин, національної безпеки, безпеки державного кордону, охорони
            здоров’я, виробництва та технологій, електроніки, автоматизації та електронних комунікації, гуманітарних
            наук, культури та мистецтва та ін.
            <br />  У структурі Організації також функціонують такі Центральні комітети Організації (перелік не є
            вичерпним), які Ви можете обрати при реєстрації Заяви:
            <br />  а) центральний комітет розвитку громад, територій та інфраструктури;
            <br />  б) центральний комітет економіки та стратегічних галузей народного господарства;
            <br />  в) центральний комітет освіти, науки, культури, молоді та спорту;
            <br />  г) центральний комітет охорони здоров’я та соціальної політики;
            <br />  д) центральний комітет внутрішніх справ та оборони;
            <br />  е) центральний комітет закордонних справ та юстиції.
            <br />   Центральні комітети створені для формування професійних кіл у відповідній сфері, можливості
            обміну досвідом, комунікаціями тощо. Організація надає такі можливості для кожного Центрального комітету
            відповідно до його потреб та запитів.
            <br /><br /> <b>  ЯКА СТРУКТУРА ОРГАНІЗАЦІЇ? </b>
            <br />  Схематичне зображення структури Організації та Положенням про структуру Організації Ви знайдете
            за посилання - <a href="https://unapam.com/structura">Структура Організації</a>  .
            На обласному рівні працюють відокремлені підрозділи, які безпосередньо діють на території
            відповідної області. Назва відокремленого підрозділу відповідає назві області.
            Відокремлені підрозділи мають наступні повноваження:
            <br />  - Представляють Організацію у межах території, на яку поширюється їх повноваження.
            <br />  - Реалізують статутні мету та завдання Організації у межах території, на яку поширюється їх
            повноваження, згідно наданих рішенням загальних зборів повноважень.
            <br />   - Проводять роботу по залученню нових членів (учасників) з використанням засобів, не
            заборонених законодавством України.
            <br /><br />  <b>  ЯКІ ПЕРЕВАГИ З’ЯВЛЯЮТЬСЯ У ЧЛЕНА ОРГАНІЗАЦІЇ? </b>
            <br />  1. Можливість сформувати особистий профіль на веб-порталі www.unapam.com , який може буде
            одночасно Вашою візитівкою, презентацією Вашого управлінського потенціалу, можливістю представити Ваші
            здобутки та напрацювання серед професійного оточення, у соціальних мережах тощо. Розділи та інформація у
            профілі – формується особисто на власний розсуд членом Організації. Організація не несе відповідальності за
            надану інформацію в особистих профілях членів Організації.
            Приклад сформованого профілю члена Організації - <a href="https://unapam.com/user/1"> Профіль члена Організації</a>    .
            <br />   2. Доступ до курсів, програм, проектів тощо щодо підвищення своєї компетентності, самоосвіти,
            професійного розвитку. Дякі курси, програми є безкоштовними для членів Організації. Посилання тут -
            <a href="https://unapam.com/kyrsu">Курси</a>    .
            <br />  3. Можливість проходити підвищення кваліфікації, міжнародні стажування, тренінги та отримати
            відповідний документ, якщо це передбачено умовами.
            <br />  4. Можливість отримати фахову правову та іншу підтримку Організації.
            <br />  5. Брати участь у порядку, визначеному законодавством, у роботі консультативних, дорадчих та
            інших допоміжних органів, що утворюються органами державної влади чи органами місцевого самоврядування
            для проведення консультацій з громадськими об&#39;єднаннями та підготовки рекомендацій з питань, що
            стосуються сфери їхньої діяльності
            <br />  6. Брати участь у виборчому процесі відповідно до чинного законодавства, через Організацію
            взаємодіяти з депутатськими комісіями і групами, співпрацювати з політичними партіями, виборчими блоками
            під час виборчої кампанії, пропонувати для включення в їх виборчі списки авторитетних членів Організації
            відповідного рівня.
            <br />  7. Здійснювати громадський контроль за додержанням законодавства про державну службу,
            службу в органах місцевого самоврядування, про працю та про охорону праці, створенням безпечних і

            нешкідливих умов праці, належних санітарно-побутових умов, забезпеченням працівників засобами
            індивідуального та колективного захисту.
            <br />  8. Організовувати та здійснювати громадський контроль за реалізацією прав членів Організації у
            сфері охорони здоров’я, медико-соціальної допомоги, житлово-комунального забезпечення, соціального
            захисту та інших прав та свобод членів Організації, громадськості.
            <br /> 9. Брати участь у діяльності експертних, консультативних та наглядових рад при органах
            публічної влади.
            <br /> 10. Перелік переваг не є вичерпним і змінюється відповідно до запитів членів Організації,
            організацій та установ сфери публічного управління та адміністрування.
            <br />11. Пропонувати свої ідеї, які не суперечать статутній діяльності Організації та чинному
            законодавству України, і ми разом їх реалізуємо!

            <br /><br /> <b> ЯК СТАТИ ЧЛЕНОМ ОРГАНІЗАЦІЇ? </b>
            <br /> Прийом у члени Організації здійснюється за таким алгоритмом:
            <br /> 1. Оформіть Заяву на ім’я Голови Правління Організації (знаходиться у розділі
            «РЕЄСТРАЦІЯ/ВХІД» у верхньому правому кутку веб-порталу www.unapam.com )
            <br /> 2. Оплатіть вступний внесок у розмірі 700,00 (сімсот грн.00 коп.) за посиланням у Заяві зручним
            для Вас способом або за реквізитами :
            <br /> РЕКВІЗИТИ РАХУНКУ
            <br />  № UA253257960000026002300840117 (UAH)
            <br /> Код отримувача 45123978
            <br /> Отримувач платежу ГРОМАДСЬКА ОРГАНІЗАЦІЯ «НАЦІОНАЛЬНА АСОЦІАЦІЯ ПУБЛІЧНОГО
            <br /> УПРАВЛІННЯ ТА АДМІНІСТРУВАННЯ УКРАЇНИ»
            <br /> Банк : Філія Львiвське обласне управління АТ &quot;ОЩАДБАНК&quot;
            <br />  МФО банку 325796
            <br />Сума до оплати: 700,00 (сімсот грн.00 коп.)
            <br /> Призначення платежу: Вступний внесок від _______ (обов’язково вкажіть прізвище, ім’я, по-
            батькові)
            <br /> 3. Очікуйте рішення щодо прийому. Статут Організації визначає, що прийом у члени Організації
            відбувається за рішенням Правління Організації, яке приймається упродовж місяця з дня здійснення оплати
            вступного внеску за умови подання відповідної заяви. Але, зазвичай, таке рішення приймається значно швидше,
            інколи у день отримання Заяви.
            <br /> 4. За бажанням Ви можете самостійно згенерувати посвідчення члена Організації у своєму
            особистому профілі. Дата, зазначена у Посвідченні є датою прийняття Вас у члени Організації.
            <br /> Також прийом заяви може відбуватися в письмовому вигляді або в електронному форматі, за
            допомогою затверджених форм заяв через веб-портал Організації чи/та на електронну пошту Організації
            officeUNAPAM@gmail.com .
            Правління Організації має право відмовити у прийнятті особи в члени Організації.
            <br /><br />  <b> ЧИ ОБОВ’ЯЗКОВОЮ Є РЕЄСТРАЦІЯ ОСОБИСТОГО ПРОФІЛЮ НА ВЕБ-ПОРТАЛІ ОРГАНІЗАЦІЇ
            www.unapam.com? </b>
            <br />  Ні, реєстрація особистого профілю на веб-порталі організації www.unapam.com є добровільною.
            Для реєстрації особистого профілю перейдіть у розділ «Реєстрація». Заповнивши Заявку, Ви можете
            скористатися можливістю створити власний особистий профіль з унікальним доменним ім’ям, яке належатиме
            лише Вашому профілю. Після реєстрації Ви можете поширювати посилання на Ваш профіль у соціальних
            мережах, своїх візитівках, резюме, презентаційних матеріалах та у будь-який інший спосіб. Термін дії домену
            обумовлений терміном перебуванням в Організації.
            <br /><br /> <b> ЯК ОТРИМАТИ СВІЙ ОСОБИСТИЙ ПРОФІЛЬ НА ВЕБ-ПОРТАЛІ ОРГАНІЗАЦІЇ
            www.unapam.com? </b>
            <br />   Для реєстрації особистого профілю перейдіть у розділ «Реєстрація». Заповнивши Заявку, Ви можете
            скористатися можливістю створити власний особистий профіль з унікальним доменним ім’ям, яке належатиме
            лише Вашому профілю.
            Приклад сформованого профілю члена Організації - <a href="https://unapam.com/user/1">Профіль члена Організації</a>  
            <br /><br /> <b> СКІЛЬКИ ЧАСУ Я МОЖУ КОРИСТУВАТИСЯ ОСОБИСТИМ ПРОФІЛЕМ НА САЙТІ? </b>
            <br />  Профіль діє на постійній основі для усіх членів Організації. Оплативши вступний внесок та ставши
            членом Організації у Вас одразу з’являється можливість сформувати свій особистий профіль, редагувати його у
            будь-який час, додавати до нього інформацію чи вилучати її з профілю, змінювати розділи тощо.
            Вступний внесок діє строком на рік. У подальшому (тобто через рік користування) потрібно оплатити
            членський внесок знову (оплата здійснюється щорічно). Тому Ваш особистий профіль буде активним стільки
            часу, скільки Ви залишатиметеся членом Організації.

            <br /><br /> <b>  ЯК Я ЗНАТИМУ КОЛИ ЗДІЙСНЮВАТИ ОПЛАТУ ЧЛЕНСЬКИХ ВНЕСКІВ? </b>
            <br /> Система веб-порталу Організації автоматично відслідковує та нагадує членам Організації усю
            необхідну інформацію за вказаними Вашими контактами у Заяві, яку Ви заповнювали при вступі до Організації.
            Тому при зміні своїх контактних даних (e-mail) просимо внести відповідну зміну у Ваш особистий
            профіль.
            <br /><br /> <b>  ЩО МОЖНА РОБИТИ В ОСОБИСТОМУ ПРОФІЛІ? </b>
            <br />   Ось такі опції доступні на сьогодні:
            <br />  - розташовувати інформацію про себе
            <br /> - формувати Розділи (давати їм назви, редагувати тощо) та наповнювати їх інформацією
            <br /> - змінювати інформацію зручним редагуванням
            <br /> - видаляти інформацію в особистому профілі
            <br /> - розміщувати фото, текстові повідомлення, посилання тощо
            <br />  - користуватися пошуковими можливостями веб-порталу, здійснювати користування базою даних вже
            зареєстрованих учасників
            <br />  - мати доступ до професійних ФОРУМІВ, де є можливість комунікації, обміну досвідом, знайомством
            з новими тенденціями та їх авторами серед фахівців сфери публічного управління та адміністрування
            <br />  - мати доступ до навчальних курсів, програм підвищення кваліфікації, грантових пропозиції та інших
            проектів. Деякі пропозиції для членів Організації є безкоштовними, про що додатково проводиться
            інформування
            <br />  - брати участь у комінікаційних заходах (конференціях, круглих столах, семінарах тощо)
            <br />  - мати доступ до консультацій (щодо кадрових, соціальних, юридичних та інших питань)
            <br /> - поширювати свою інформацію (з метою інформування інших членів Організації, обговорення
            проблемних питань тощо) серед фахівців сфери публічного управління та адміністрування
            <br />   - бути запрошеним до заходів/комінікацій/тощо з партнерами організації, брати участь у спільних
            програмах та проектах
            <br /><br /> <b> ЧИ Є ПЕРЕВАГИ КОРИСТУВАННЯ ВЕБ-ПОРТАЛОМ ДЛЯ ЧЛЕНІВ ОРГАНІЗАЦІЇ? </b>
          <br />  Так. Для членів Організації на веб-порталі є значно більше послуг, які закриті для інших відвідувачів.
            <br />  Зокрема, це:
            <br /> 1. Можливість отримати особистий профіль, який може буде одночасно Вашою візитівкою,
            презентацією Вашого управлінського потенціалу, можливістю представити Ваші здобутки та напрацювання
            серед професійного оточення.
            Для реєстрації особистого профілю перейдіть у розділ «Реєстрація». Заповнивши Заявку, Ви можете
            скористатися можливістю створити власний особистий профіль з унікальним доменним ім’ям, яке належатиме
            лише Вашому профілю. Після реєстрації Ви можете поширювати посилання на Ваш профіль у соціальних
            мережах, своїх візитівках, резюме, презентаційних матеріалах та у будь-який інший спосіб. Термін дії Вашого
            особистого профілю обумовлений терміном перебуванням в Організації.
            <br /> 2. Доступ до площадок Форумів, Центральних комітетів, інших підрозділів Організації.
            <br />  3. Доступ до діяльності Центральних комітетів за галузевим спрямуванням, а саме:
            <br />  - а) центральний комітет розвитку громад, територій та інфраструктури;
            <br /> - б) центральний комітет економіки та стратегічних галузей народного господарства;
            <br /> - в) центральний комітет освіти, науки, культури, молоді та спорту;
            <br />  - г) центральний комітет охорони здоров’я та соціальної політики;
            <br /> - д) центральний комітет внутрішніх справ та оборони;
            <br /> - е) центральний комітет закордонних справ та юстиції.
            <br /> 4. Доступ до безкоштовних курсів/програм/проектів, міжнародних проектів, грантових програм,
            тренінгів, круглих столів тощо.
            <br /> 5. Доступ до зібрань Асамблеї Організації, в Асамблеї беруть участь її члени особисто чи через
            уповноваженого представника за довіреністю. Кожний член Організації має один голос.
            <br /> 6. Можливість долучатися до галузевих програм, територіального розвитку (на обласному,
            районному, локальному рівнях) тощо.
            <br />  7. Це невичерпний перелік, адже Організація сприятиме розвитку інших напрямів відповідно до
            запитів членів Організації, актуальних питань розвитку сфери публічного управління та адміністрування.
            <br /><br /> <b> ЯКІ НАПРЯМАМИ ДІЯЛЬНОСТІ ОРГАНІЗАЦІЇ? </b>
            <br />  - Сприяння утвердженню принципу гласності та відкритості у діяльності органів державної влади,
            органів місцевого самоврядування, інших органів публічного управління;
            <br /> - Сприяння здійсненню громадського контролю за діями влади з метою дотримання принципу
            служіння народу, недопущення свавілля чиновників, інших публічних управлінців та службовців у сфері
            публічного управління;
            <br /> - Підтримання законності в суспільстві, захисту прав людини та громадянина;
            <br /> - Сприяння професійному розвитку публічних управлінців та публічних службовців;
            <br /> - Надання юридичних, кадрових, інформаційних та інших видів консультацій, та представництво
            інтересів громадян;

            <br /> - Сприяння в наданні підтримки іншим неприбутковим організаціям у межах та в способи, що
            передбачаються законодавством;
            <br />  - Здійснення заходів щодо підвищення професійної компетентності публічних управлінців та
            публічних службовців;
            <br /> - Залучення міжнародної спільноти до вирішення вітчизняних проблем в сфері публічного управління
            та адміністрування;
            <br /> - Проведення комунікаційних, консультаційних, освітніх та інших заходів щодо розвитку кадрового
            потенціалу сфери публічного управління та адміністрування, підвищення якості надання публічних послуг,
            підвищення ефективності діяльності публічних інституцій;
            <br />  - Сприяти формуванню та розвитку кадрового потенціалу у сфері публічного управління;
            <br />  - Сприяння вирішенню трудових спорів, конфліктних ситуацій, дискусійних питань кадрової
            політики в органах публічної влади та інших публічних інституцій;
            <br /> - Сприяння розвитку інформаційного, кадрового, ресурсного забезпечення сфери діяльності органів
            публічної влади, публічних інституцій та їх посадових осіб.
            <br /> - Сприяння формуванню професійних знань, вмінь та навичок у професійній діяльності публічних
            управлінців;
            <br /> - Залучення стейкхолдерів до процесів формування та розвитку публічного-управлінського
            потенціалу, сприяння врахування їх інтересів у своїй діяльності, а також у діяльності публічних інституцій;
            <br /> - Впровадження міжнародних стандартів, позитивного міжнародного досвіду у практичну діяльність
            публічних управлінців, їх теоретичну підготовку, у процеси формування кадрового потенціалу сфери
            публічного управління.
            <br /> - Сприяння захисту трудових, соціально-економічних прав та інтересів, захист прав і свобод,
            задоволення професійних, суспільних, культурних, екологічних, та інших інтересів її членів та громадян
            України, сприяння розвитку сфери публічного управління та адміністрування, розвитку та діяльності публічних
            інституцій, публічних управлінців та інших сфер, які стосуються визначеної мети діяльності Організації усіма
            можливими методами, способами та шляхами, що не суперечать чинному законодавству України.
            <br /><br /> <b>  ЯКИЙ ПОРЯДОК ПРИПИНЕННЯ ЧЛЕНСТВА В ОРГАНІЗАЦІЇ? </b>
            <br /> Членство в Організації є добровільним та індивідуальним.
            Кожний член Організації має право вільно виходити з Організації за власною письмовою заявою за
            умови відсутності невиконаних зобов’язань під час терміну перебування в Організації
            Членство в Організації припиняється у випадках:
            виходу із Організації за власним бажанням. Про припинення членства в Організації Ви можете подати
            відповідну Заяву на Голову правління Організації;
            виключення із Організації, за рішенням Правління, у зв’язку із порушенням вимог Статуту, або якщо
            діяльність члена суперечить меті та завданням Організації, або якщо член втратив зв’язок із Організацією без
            поважних причин чи за несплату членських внесків;
            смерті члена Організації.
            <br />  Підстави для виключення з членів Організації:
            <br />  – порушення вимог Статуту;
            <br />  – неучасть в діяльності Організації особисто або через представника протягом принаймні 6
            (шести) місяців;
            <br />  – невиконання рішення/рішень керівних органів Організації;
            <br />   – несплати членських внесків протягом останнього року.
            <br />   Питання про виключення вирішується Правлінням Організації.
        </div>
    )
}