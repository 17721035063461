import { withRouter } from "react-router-dom";
import { Footer } from "../components/Footer/footer";
import Header from "../components/header/header";

 const Layout = (props: any) => (
    <div >
      <Header />
        <main>
        <div className="o-container">
         {props.children}
          </div>
        </main>
      <Footer />
    </div>
   );

   export default withRouter(Layout);