import { useContext, useEffect, useState } from 'react';
import { addNews, getLatestNews, getLatestUser } from '../../services/firebase-store.service';
import './home.css';
import { User } from '../../types/auth';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import { Modal_Confirm } from "../../components/modals/modal";
import { getDateFormat } from '../../helpers/date-format';
import { Modal_Body } from '../../components/modals/modal-body';
import { News } from '../../types/news';
import ReactQuill from 'react-quill';
import GoogleSlider from '../../components/GoogleSlider/google_slider';
import { InfoBox } from '../../components/info-box/infoBos';

export const Home = () => {
  const { setLoading, user } = useContext(AuthContext);
  const [_news, setNews] = useState<any[]>([]);
  const [addingNews, setAddingNews] = useState<boolean>(false);

  useEffect(() => { getInfo() }, []);
  useEffect(() => { window.scrollTo(0, 0) }, [])

  const getInfo = async () => {
    setLoading(true);
    const a = await getLatestNews(new Date().getTime())
    a.sort((a: News, b: News) => +b.date - +a.date)
    setNews(a)
    setLoading(false);
  }

  const onCancelNews = () => {
    document.body.style.overflow = 'visible';
    setAddingNews(false);
  }

  const onAddNews = async (a: any) => {
    setLoading(true);
    const newNews = await addNews(a)
    const as = _news;
    as.unshift(newNews)
    setNews(as)
    setLoading(false);
  }

  const confirm = (value: string, i: number, isEdit: boolean) => {
    value = value.trim().replaceAll(/\s+/g, '_');
  }

  const a = Modal_Body(onAddNews, onCancelNews, null)

  return <div className="mx-4 home">

    <div className="contaiter pt-4">
      <div className="row">

        <div className="col-md-8 col-sm-12">
          <h2 className='pb-4'>Національна асоціація публічного управління та адміністрування України</h2>
          <p>
            Вітаємо на веб-порталі Національної асоціації публічного управління та адміністрування України!
          </p>
          <p>
            Громадська організація «Національна асоціація публічного управління та
            адміністрування України» (далі-Організація) є добровільним об&#39;єднанням фізичних осіб,

            створеним для здійснення та захисту прав і свобод людини та громадянина, задоволення
            професійних, суспільних, економічних, соціальних, культурних, освітніх та інших інтересів
            своїх членів та/або інших осіб.
          </p>
          <p>
            Метою (ціллю) Організації є здійснення захисту трудових, соціально-економічних
            прав та інтересів, захист прав і свобод, задоволення професійних, суспільних, культурних,
            екологічних, та інших інтересів її членів та громадян України, сприяння розвитку сфери
            публічного управління та адміністрування, розвитку та діяльності публічних інституцій,
            публічних управлінців та інших сфер, які стосуються визначеної мети діяльності Організації
          </p>

          <InfoBox 
          text={`Можливість сформувати особистий профіль на веб-порталі www.unapam.com , який може буде одночасно Вашою візитівкою, презентацією Вашого управлінського потенціалу, можливістю представити Ваші здобутки та напрацювання серед професійного оточення, у соціальних мережах тощо. Розділи та інформація у профілі – формується особисто на власний розсуд членом Організації. Приклад сформованого Профілю члена Організації - `} 
          link={{name: 'Профіль члена Організації', value: 'https://unapam.com/user/1'}}
          
          />
          <InfoBox 
          text={'Доступ до курсів, програм, проектів тощо щодо підвищення своєї компетентності, самоосвіти, професійного розвитку. Деякі курси, програми є безкоштовними для членів Організації. Посилання тут - '} 
          position={'right'}
          link={{name: 'Курси', value: 'https://unapam.com/kyrsu'}}

          />
          <InfoBox 
          text={'Можливість проходити підвищення кваліфікації, міжнародні стажування, тренінги та отримати відповідний документ, якщо це передбачено умовами.'} 
          // position={'right'}

          />
          <InfoBox 
          text={'Можливість отримати фахову правову та іншу підтримку Організації.'} 
          position={'right'}
          link={{name: 'Правнича допомога', value: 'https://unapam.com/law'}}

          />
          <InfoBox 
          text={`Брати участь у порядку, визначеному законодавством, у роботі консультативних, дорадчих та інших допоміжних органів, що утворюються органами державної влади чи органами місцевого самоврядування для проведення консультацій з громадськими об'єднаннями та підготовки рекомендацій з питань, що стосуються сфери їхньої діяльності.`} 
          // position={'right'}

          />
          <InfoBox 
          text={`Брати участь у виборчому процесі відповідно до чинного законодавства, через Організацію взаємодіяти з депутатськими комісіями і групами, співпрацювати з політичними партіями, виборчими блоками під час виборчої кампанії, пропонувати для включення в їх виборчі списки авторитетних членів Організації відповідного рівня.`} 
          position={'right'}

          />
          <InfoBox 
          text={`Здійснювати громадський контроль за додержанням законодавства про державну службу, службу в органах місцевого самоврядування, про працю та про охорону праці, створенням безпечних і нешкідливих умов праці, належних санітарно-побутових умов, забезпеченням працівників засобами індивідуального та колективного захисту.`} 
          // position={'right'}

          />
          <InfoBox 
          text={`Організовувати та здійснювати громадський контроль за реалізацією прав членів Організації у сфері охорони здоров’я, медико-соціальної допомоги, житлово-комунального забезпечення, соціального захисту та інших прав та свобод членів Організації, громадськості.`} 
          position={'right'}

          />
          <InfoBox 
          text={` Брати участь у діяльності експертних, консультативних та наглядових рад при органах публічної влади.`} 
          // position={'right'}

          />


          <p className='mt-4'>
            Запрошуємо усіх членів Національної асоціації публічного управління та
            адміністрування України (далі –НАПУАУ чи/та Організація) скористатися
            можливістю отримати особистий профіль, який може буде одночасно Вашою
            візитівкою, презентацією Вашого управлінського потенціалу, можливістю
            представити Ваші здобутки та напрацювання серед професійного оточення.

          </p>
          <p>
            Для реєстрації особистого профілю перейдіть у розділ «Реєстрація».
            Заповнивши Заявку, Ви можете скористатися можливістю створити власний
            особистий профіль з унікальним доменним ім’ям, яке належатиме лише
            Вашому профілю. Після реєстрації Ви можете поширювати посилання на Ваш
            профіль у соціальних мережах, своїх візитівках, резюме, презентаційних
            матеріалах та у будь-який інший спосіб.
          </p>
          <p>
            Термін дії домену обумовлений терміном перебуванням у НАПУАУ.
          </p>

          <div className='text-center'>
            <GoogleSlider height={430} width={700} url={'https://docs.google.com/presentation/d/14GCX8QnbqITIu_5EsD8jwIfZywEqMTu65Jllo9tqoNU/edit#slide=id.p1'} />
          </div>

          <p className='mt-5'>
            <h5>МОЖЛИВОСТІ ОСОБИСТОГО ПРОФІЛЮ: </h5>
          </p>

          <p>
            <b> ІНФОРМАЦІЙНІ МОЖЛИВОСТІ </b>
          </p>
          <p>
            Тепер про Вас можна довідатися більше! УВАГА - усі дані, розміщені на Вашому особистому профілі одразу потрапляють у публічний доступ.
          </p>

          <p>
            <b> Що можна робити в особистому профілі? </b> Ось такі опції доступні на сьогодні:
          </p>
          <p>
            - розташовувати інформацію про себе в особистому профілі
          </p>
          <p>
            - формувати про себе Розділи (давати їм назви, редагувати тощо) та наповнювати їх інформацією
          </p>
          <p>
            - змінювати інформацію зручним редагуванням
          </p>
          <p>
            - видаляти інформацію в особистому профілі
          </p>
          <p>
            - розміщувати фото, текстові повідомлення, посилання тощо
          </p>
          <p>
            - користуватися пошуковими можливостями веб-порталу, здійснювати користування базою даних вже зареєстрованих учасників
          </p>
          <p>
            - мати доступ до професійних ФОРУМІВ, де є можливість комунікації, обміну досвідом, знайомством з новими тенденціями та їх авторами серед фахівців сфери публічного управління та адміністрування
          </p>
          <p>
            - мати доступ до навчальних курсів, програм підвищення кваліфікації, грантових пропозиції та інших проектів. Деякі пропозиції для членів НАПУАУ є безкоштовними, про що додатково проводиться інформування
          </p>
          <p>
            - брати участь у комінікаційних заходах (конференціях, круглих столах, семінарах тощо)

          </p>
          <p>
            - мати доступ до консультацій (щодо кадрових, соціальних, юридичних та інших питань)
          </p>
          <p>
            - поширювати свою інформацію (з метою інформування інших членів НАПУАУ, обговорення проблемних питань тощо) серед фахівців сфери публічного управління та адміністрування
          </p>
          <p>
            - бути запрошеним до заходів/комінікацій/тощо з партнерами НАПУАУ, брати участь у спільних програмах та проектах
          </p>
          <p>
            - пропонувати свої ідеї, які не суперечать статутній діяльності НАПУАУ, і ми разом їх втілемо!
          </p>
          <p>
            <br />
            <b>КОМУНІКАЦІЙНІ МОЖЛИВОСТІ  </b>
          </p>
          <p>
            На веб-порталі працюють професійні ФОРУМИ – це площадки для комунікацій та обміну досвідом за певними напрямами. ФОРУМИ працюють за галузевою ознакою та відповідно до напрямків дільності Центральних комітетів НАПУАУ. За зверненнями членів НАПУАУ, враховуючи актуальність та потребу у розширенні тематики форумів, НАПУАУ планує розширяти перелік ФОРУМІВ за іншими напрямками (темами). Відповідно на площадках ФОРУМІВ можна буде знайти відповіді на гострі питання, поспілкуватися з відповідною професійною аудиторією, взяти участь у дискусіях, ознайомитися з думками колег.

          </p>
          <p>
            У меню веб-портала знаходиться опція ПОШУК, скориставшись якою Ви можете знайти інформацію щодо членів НАПУАУ за географічною ознакою (критерій – область), за особистими даними (критерій – прізвище, імя, по-батькові).

          </p>
          <p>
            НАПУАУ запрошує до цікавих дискусій, проектів та співпраці!
          </p>

          <p>
            <br />
            <b>ОСВІТНІ МОЖЛИВОСТІ  </b>
          </p>
          <p>
            НАПУАУ здійснює свою діяльність і в освітньому напрямку, зокрема через поширення програм підвищення кваліфікації обсягом 0,2 – 1 кредит ЄКТС. Напрями програм будуть додаватися відповідно до переліку пріоритетних напрямів (тем) підвищення кваліфікації державних службовців за загальними професійними (сертифікатними) та короткостроковими програмами.
          </p>
          <p>
            Програм охоплюватимуть загальні питання державного управління та місцевого самоврядування, питання запобігання корупції, зміни у законодавстві з питань державної служби, служби в органах місцевого самоврядування, європейської та євроатлантичної інтеграції, із забезпечення рівних прав та можливостей жінок та чоловіків, підвищення рівня володіння іноземною мовою, яка є офіційною мовою Ради Європи, державною мовою, а також інші питання, визначені відповідними державними, регіональними, місцевими програмами, стратегіями.

          </p>
          <p>
            Члени НАПУАУ мають можливість скористатися такою послугою, пройти програму у зручний для себе час, отримати відповідний документ, що підтверджує проходження програми. Документ генерується на веб-порталі через власний особистий кабінет члена НАПУАУ.

          </p>
          <p>
            За потреби програми можна укладати та розміщувати на веб-порталі відповідно до цільової аудиторії за певними напрямами (темами). НАПУАУ запрошує долучатися до обговорення актуальних тем підвищення кваліфікації, за якими  в короткі терміни може бути запроваджено програму чи курс.

          </p>

          <br /> <br />
          <p>
            Громадська організація <b> «Національна асоціація публічного управління та адміністрування України» </b>(далі-Організація) є добровільним об'єднанням фізичних осіб, створеним для здійснення та захисту прав і свобод людини та громадянина, задоволення професійних, суспільних, економічних, соціальних, культурних, освітніх та інших інтересів своїх членів та/або інших осіб.
          </p>

          <p>
            Метою (ціллю) Організації є здійснення захисту трудових, соціально-економічних прав та інтересів, захист прав і свобод, задоволення професійних, суспільних, культурних, екологічних, та інших інтересів її членів та громадян України, сприяння розвитку сфери публічного управління та адміністрування, розвитку та діяльності публічних інституцій, публічних управлінців та інших сфер, які стосуються визначеної мети діяльності Організації.
          </p>

          <p>
            <b> Напрямами (завдання)  діяльності Організації є: </b>
            <p>
              - Сприяння утвердженню принципу гласності та відкритості у діяльності органів державної влади, органів місцевого самоврядування, інших органів публічного управління;
            </p>
            <p>
              - Сприяння здійсненню громадського контролю за діями влади з метою дотримання принципу служіння народу, недопущення свавілля чиновників, інших публічних управлінців та службовців у сфері публічного управління;
            </p>
            <p>
              - Підтримання законності в суспільстві, захисту прав людини та громадянина;
            </p>
            <p>
              - Сприяння професійному розвитку публічних управлінців та публічних службовців;
            </p>
            <p>
              - Надання юридичних, кадрових, інформаційних та інших видів консультацій, та представництво інтересів громадян;
            </p>
            <p>
              - Сприяння в наданні безкорисної допомоги;
            </p>
            <p>
              - Сприяння в наданні підтримки іншим неприбутковим організаціям у межах та в способи, що передбачаються законодавством;
            </p>
            <p>
              -  Здійснення заходів щодо підвищення професійної компетентності публічних управлінців та публічних службовців;
            </p>
            <p>
              - Залучення міжнародної спільноти до вирішення вітчизняних проблем в сфері публічного управління та адміністрування;
            </p>
            <p>
              -  Проведення комунікаційних, консультаційних, освітніх та інших заходів щодо розвитку кадрового потенціалу сфери публічного управління та адміністрування, підвищення якості надання публічних послуг, підвищення ефективності діяльності публічних інституцій;
            </p>
            <p>
              - Сприяти формуванню та розвитку кадрового потенціалу у сфері публічного управління;
            </p>
            <p>
              - Сприяння вирішенню трудових спорів, конфліктних ситуацій, дискусійних питань кадрової політики в органах публічної влади та інших публічних інституцій;
            </p>
            <p>
              - Сприяння розвитку інформаційного, кадрового, ресурсного забезпечення сфери діяльності органів публічної влади, публічних інституцій та їх посадових осіб;
            </p>
            <p>
              - Сприяння формуванню професійних знань, вмінь та навичок у професійній діяльності публічних управлінців;
            </p>
            <p>
              - Залучення стейкхолдерів до процесів формування та розвитку публічного-управлінського потенціалу, сприяння врахування їх інтересів у своїй діяльності, а також у діяльності публічних інституцій;
            </p>
            <p>
              - Впровадження міжнародних стандартів, позитивного міжнародного досвіду у практичну діяльність публічних управлінців, їх теоретичну підготовку, у процеси формування кадрового потенціалу сфери публічного управління;
            </p>
            <p>
              - Сприяння захисту трудових, соціально-економічних прав та інтересів, захист прав і свобод, задоволення професійних, суспільних, культурних, екологічних, та інших інтересів її членів та громадян України, сприяння розвитку сфери публічного управління та адміністрування, розвитку та діяльності публічних інституцій, публічних управлінців та інших сфер, які стосуються визначеної мети діяльності Організації усіма можливими методами, способами та шляхами, що не суперечать чинному законодавству України.
            </p>
          </p>

          <p>
            Найменування Організації українською мовою: Повне найменування Організації – Громадська організація «Національна асоціація публічного управління та адміністрування України». Скорочене найменування Організації – ГО «НАПУАУ».
          </p>

          <p>
            Назва Організації іноземною мовою: Повна назва Організації англійською мовою – Ukrainian National Association of Public Administration and Management.
            Скорочена назва Організації англійською мовою – UNAPAM.
          </p>
        </div>

        <div className="col-md-4 col-sm-12 mt-2">
          <div className="new-item">
            {addingNews ? a : null}
            <div className="new-item-title">Останні новини</div>
            {user?.role == 'publisher' || user?.role == 'admin' ?
              <div className="new-item-body">
                <button className="btn btn-primary" onClick={() => { setAddingNews(true); document.body.style.overflow = 'hidden' }}>Додати новину</button>
              </div>
              : null}
            {
              _news.map((news: News, i: number) =>
                <div key={i} className="new-item-body ">
                  <NavLink to={`/news/${news.date}`}>
                    <div className="pl-2   sub-title">
                      Дата: {getDateFormat(new Date(news.date))}
                    </div>
                    <div className='new-item-name'>

                      {news?.name}
                    </div>
                    <div className='sub-title'>
                      {/* <ReactQuill
                            readOnly={true}
                            theme={"bubble"}
                            value={news?.text}

                          /> */}
                      <pre>
                        <b>

                          {news?.shortText?.length > 1 ? news.shortText : news?.text}
                        </b>
                      </pre>
                    </div>
                    <img src={news?.imgLink} alt="" />
                    <img src={news?.img} alt="" />

                  </NavLink>
                  <div className="pt-2">
                    {
                      news?.links?.map((link: { link: string, linkName: string, }, i: number) =>
                        <a key={i} target="_blank" href={link.link}>{link.linkName ? link.linkName : link.link}</a>
                      )
                    }

                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </div>



  </div >

}
